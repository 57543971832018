import styled from 'styled-components';
import { Button, experimentalStyled, Box, SxProps, Typography, ListItem } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import { colors } from '../../static/constants';

export const dropdownIcon = '/images/dropdown_white.svg';
export const blackDropdownArrow = '/images/dropdown_black.svg';

export const StyledButtonsContainer = styled(Box)`
  margin-top: 5px;
  width: '100%';
  gap: 5px;
  flex-wrap: wrap;
  display: flex;
  && .question-list {
    flex-wrap: wrap;
  }
  && .add-btn {
    padding: 0px !important;
    align-items: flex-start;
  }
`;

export const QuestionNumberButton = styled(Box)<{ $Active?: boolean; $AddIcon?: boolean }>`
  width: 22px;
  height: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid ${(props) => (props.$AddIcon ? 'var(--first-color)' : 'black')};
  border-radius: 5px;
  font-family: var(--bodyText-font-family);
  font-style: normal;
  font-size: 14px;
  color: ${(props) => (props.$Active ? 'white' : 'black')};
  color: ${(props) => props.$AddIcon && 'var(--first-color)'};
  background-color: ${(props) => (props.$Active ? 'var(--first-color)' : 'white')};
  background-color: ${(props) => props.$AddIcon && 'transparent'};
  &:hover {
    cursor: pointer;
  }
`;

export const StyledAddQuestionContainer = experimentalStyled(Box)({
  padding: '0.5rem',
  margin: '15px 10px 10px 10px',
  background: '#15182b',
  boxshadow: '1px 1px 8px rgba(0, 0, 0, 0.08)',
});

export const CodingContainer = styled(Box)<{ $isDark?: boolean }>`
  display: flex;
  width: ${(props) => (props.$isDark ? '50vw' : '100%')};
  padding: 0.6vw 1vw 1vw 1vw;
  display: flex;
  flex-direction: column;
  gap: 20px;
  && .monaco-editor {
    padding-top: 8px;
    background-color: ${(props) => (props.$isDark ? '#FFFFFF' : '#000412')};
  }
  && .monaco-editor .margin {
    background-color: ${(props) => (props.$isDark ? '#FFFFFF' : '#000412')};
  }
  && .monaco-editor-background {
    background-color: ${(props) => (props.$isDark ? '#FFFFFF' : '#000412')};
  }
  && canvas.minimap-decorations-layer {
    background-color: ${(props) => (props.$isDark ? '#FFFFFF' : '#000412')};
  }
  && canvas.decorationsOverviewRuler {
    background-color: ${(props) => (props.$isDark ? '#FFFFFF' : '#000412')};
    width: 3px !important;
  }
  && .monaco-scrollable-element > .scrollbar > .slider {
    background-color: var(--first-color);
    width: 3px !important;
  }
  && .monaco-editor .vertical {
    background-color: #000412;
    width: 3px !important;
  }
`;

export const CodingAndOutputContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  gap: 10px;
  height: 82.5vh;
  max-height: 82.5vh;
  width: 100%;
`;

export const EditorContainer = styled(Box)<{ $isOutputConsoleOpen?: boolean }>`
  height: 100%;
  resize: ${(props) => (props.$isOutputConsoleOpen ? 'horizontal' : 'none')};
  overflow: hidden;
  min-width: 60%;
  width: ${(props) => (props.$isOutputConsoleOpen ? '60%' : '100% !important')};
`;

export const OutputConsole = styled(Box)<{ $isOutputConsoleOpen?: boolean }>`
  height: 100%;
  min-width: 40px;
  width: ${(props) => (props.$isOutputConsoleOpen ? '39%' : '40px')};
  border-radius: 5px;
  background-color: ${(props) => (props.$isOutputConsoleOpen ? 'black' : '#15182B')};
  font-family: var(--bodyText-font-family);
`;

export const ArrowBox = styled(Box)<{ $isOutputConsoleOpen?: boolean }>`
  height: 36px;
  width: 40px;
  border-radius: ${(props) =>
    props.$isOutputConsoleOpen ? '5px 0px 0px 0px' : '5px 5px 0px 0px'};
  background-color: #323967;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    cursor: pointer;
  }
`;

export const ConsoleHeader = styled(Box)<{ $isOutputConsoleOpen?: boolean }>`
  width: 100%;
  height: 36px;
  color: white;
  display: flex;
  border-bottom: 1px solid #323967;
`;

export const ConsoleText = styled(Box)<{ $isOutputConsoleOpen?: boolean }>`
  width: 100%;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 10px 10px 10px;
`;

export const VerticalTextSx = {
  color: 'white',
  writingMode: 'vertical-rl',
  textOrientation: 'upright',
  textAlign: 'center',
  padding: '10px 0px 0px 9px',
};

export const Header = styled(Box)`
  display: flex;
  justify-content: space-between;
  gap: 20px;
  align-items: center;
`;

export const ZecodeLogo = styled.img`
  width: 9.6px;
  min-width: 40px;
`;

export const ResetLogo = styled.img`
  width: 9.6px;
`;

export const CandidateLogo = styled.img`
  width: 15px;
  height: 14px;
  min-width: 23px;
`;

export const ZesstaLogo = styled.img`
  padding: 25px;
  width: 75px;
  height: 88px;
  border-radius: 50%;
`;

export const ClearLog = styled.span<{ $isDark?: boolean }>`
  cursor: pointer;
  color: ${(props) => (props.$isDark ? '#1e1e1e' : '#f1f1f1')};
  font-family: var(--bodyText-font-family);
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  text-decoration-line: underline;
  &:hover {
    color: ${(props) => (props.$isDark ? '#1e1e1e' : 'var(--first-color)')};
  }
`;

export const PreWrapper = styled(Box)<{
  $isDark?: boolean;
  $background?: boolean;
}>`
  padding: 0.5rem 0rem 0.5rem 0.5rem;
  height: 94%;
  border-radius: 5px;
  overflow-x: auto;
  white-space: break-spaces;
  color: ${(props) => (props.$isDark ? '#1e1e1e' : '#FFFFFF')};
  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: var(--first-color);
  }
  && table thead tr th,
  table tbody tr td {
    color: ${(props) => (props.$isDark ? '#1e1e1e' : '#FFFFFF')};
    border: ${(props) => (props.$isDark ? '1px dashed #1e1e1e' : '1px dashed #FFFFFF')};
    padding: 5px;
  }
  && table {
    border-collapse: collapse;
  }
`;

export const StyledQuestionButton = experimentalStyled(Button)({
  height: '20px',
  width: '10%',
  margin: '0px 8px 10px 0px',
  borderRadius: '4px',
  background: '#1E1B31',
  whiteSpace: 'nowrap',
  textTransform: 'none',
  fontSize: '10px !important',
  padding: '0px !important',
  fontWeight: '300',
  boxShadow: '1px 1px 8px rgba(0, 0, 0, 0.08)',
  border: '0.5px solid var(--first-color)',
  fontFamily: 'var(--bodyText-font-family)',
});

export const JoinButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 7vw;
  font-family: var(--bodyText-font-family);
  font-size: 14px;
`;

export const StyledSideContainer = styled.div<{ $isDark?: boolean }>`
  width: 29vw;
  display: flex;
  flex-direction: column;
  border-right: 4px solid #d9dee6;
  min-width: 250px;
  gap: 20px;
  padding: 1vw 1vw 0 1vw;
  max-height: calc(100vh - 50px);
  min-height: calc(100vh - 50px);
  overflow: auto;
  background-color: ${(props) => (props.$isDark ? '#F2F2F2' : '#1E1B31')};
  color: #ffffff;
`;

export const CodingButtonsContainer = styled(Box)<{ $isDark?: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 5px;
  align-items: center;
`;

export const EditorWrap = styled(Box)`
  display: flex;
  width: 100%;
  min-height: calc(100vh - 50px);
`;

export const Questions = styled.span`
  color: white;
  margin-bottom: 5px;
  font-family: var(--bodyText-font-family);
`;

export const StyledMainContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 80%;
  border-left: 1px solid black !important;
  overflow: hidden;
`;

export const StyledJupyterContainer = styled.div`
  width: 100vw;
  display: flex;
  flex-direction: column;
  gap: 5px;
  border-left: 1px solid black !important;
  max-height: calc(100vh - 50px);
  overflow: auto;
`;

export const StyledContainer = styled(Box)<{ $isDark?: boolean }>`
  display: flex;
  background-color: ${(props) => (props.$isDark ? '#F2F2F2' : '#1E1B31')};
`;

export const StyledLeftContainer = styled(Box)<{ $isDark?: boolean }>`
  width: 100%;
  overflow: hidden;
  background-color: ${(props) => (props.$isDark ? '#F2F2F2' : '#1E1B31')};
`;

export const DeleteBox = styled(Box)<{ $isDark?: boolean }>`
  &:hover {
    cursor: pointer;
  }
`;

export const StyledQuestionContainer = styled.div<{
  $isDark?: boolean;
  $interviewer: boolean;
}>`
  color: #ffffff;
  padding: 0.5vw 0.5vw 0 0.5vw;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  && .quill {
    flex: 100%;
    background-color: ${(props) => (props.$isDark ? '#F2F2F2' : '#1E1B31')};
  }
  && .ql-container {
    max-height: ${(props) => (props.$interviewer ? '84%' : '98%')};
    border: 1px solid transparent;
    border-top: 1px solid #211d3b !important;
    background-color: #15182b;
    box-shadow: ${(props) => !props.$interviewer && '1px 1px 8px rgba(0, 0, 0, 0.08)'};
    border-radius: ${(props) => !props.$interviewer && '5px'};
  }
  && .ql-toolbar .ql-picker {
    color: ${(props) => !props.$isDark && '#fff'};
    font-family: var(--bodyText-font-family);
    font-size: 12px;
  }
  && .ql-toolbar .ql-stroke {
    fill: none;
    stroke: ${(props) => !props.$isDark && '#fff'};
  }
  && .ql-toolbar .ql-fill {
    fill: ${(props) => !props.$isDark && '#fff'};
    stroke: none;
  }
  && .ql-editor.ql-blank::before {
    color: ${(props) => !props.$isDark && '#fff'};
  }
  && .MuiButtonBase-root.MuiIconButton-root.MuiIconButton-sizeMedium svg {
    fill: ${(props) => !props.$isDark && '#fff'};
  }
  && .ql-container .ql-editor p {
    color: ${(props) => !props.$isDark && '#fff'};
  }
  && .ql-toolbar.ql-snow {
    display: ${(props) => !props.$interviewer && 'none'};
    border: 1px solid transparent;
    background-color: #15182b;
    height: 90px !important;
  }
  && .ql-editor {
    height: ${(props) => (props.$interviewer ? 'calc(100vh - 260px)' : 'calc(100vh - 168px)')};
    overflow: auto !important;
    font-family: var(--bodyText-font-family);
  }
  && .ql-editor::-webkit-scrollbar {
    width: 3px; /* width of the scrollbar */
  }
  && .ql-editor::-webkit-scrollbar-track {
    background: transparent !important;
  }
  && .ql-editor::-webkit-scrollbar-thumb {
    background-color: var(--first-color);
    border-radius: 5px; /* roundness of the thumb */
  }
  && .ql-editor::-webkit-scrollbar-thumb:hover {
    background-color: var(--first-color);
  }

  && .ql-snow .ql-picker-options {
    background-color: #15182b;
  }
`;

export const StyledHeader = styled(Box)`
  display: flex;
  position: sticky;
  height: 50px;
  top: 0;
  justify-content: space-between;
  align-items: center;
  background-color: #000821;
  z-index: 1;
  padding: 0.5vw 1vw;
`;

export const StyledButtonContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  gap: 10px;
`;

export const ResetButton = styled.button`
  padding: 5px !important;
  border: 1px solid #d9d9d9;
  background-color: transparent;
  border-radius: 5px;
  display: flex;
  gap: 3px;
  justify-content: space-around;
  align-items: center;
  color: #ffffff;
  font-size: 12px;
  font-weight: 500;
  font-family: var(--bodyText-font-family);
  height: 26px;
  &:hover {
    box-shadow: 0 0 14px #d9d9d9;
  }
`;

export const Run = styled.span`
  font-size: 12px;
  color: #f1f1f1;
`;

export const AcceptCallPopup = styled(Box)<{ isFeedbackSubmission?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: fixed;
  height: 300px;
  width: 250px;
  z-index: 15;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.14);
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 5px;
  padding: 30px 28px;
  background-color: rgba(255, 255, 255, 1);
`;

export const IframWrapper = styled.iframe`
  border: none;
  height: 99%;
  width: 100%;
`;

export const DropDownContainer = styled(Box)<{ isInterviewer?: boolean }>`
  font-family: var(--bodyText-font-family);
  text-transform: none !important;
  // pointer-events: ${(props) => props.isInterviewer && 'none'};
`;

export const DropDownHead = styled(Box)<{ isDisplay?: boolean; isInterviewer?: boolean }>`
  background-color: var(--first-color);
  color: var(--button-text-color);
  font-weight: 500;
  cursor: ${(props) => (!props.isInterviewer ? 'not-allowed' : 'pointer')};
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 200px;
  min-width: 120px;
  height: 26px;
  border: none;
  padding: 0px 10px;
  outline: none;
  font-size: 12px;
  && img {
    height: 25px;
    transform: ${(props) => (props.isDisplay ? 'rotate(0deg)' : 'rotate(180deg)')};
  }
`;

export const Label = styled(Typography)<{ isInterviewer?: boolean }>`
  cursor: ${(props) => (!props.isInterviewer ? 'not-allowed' : 'pointer')};
`;

export const DropdownBox = styled(Box)`
  display: flex;
  color: #f1f1f1;
  position: absolute;
  gap: 15px;
  z-index: 1;
  background-color: #15182b;
  border-radius: 5px;
  margin: 1px 0px;
  padding: 10px;
  font-size: 12px;
  & p {
    font-weight: 500;
    margin-bottom: 10px;
  }
`;

export const ListOptions = styled(Box)`
  display: flex;
  && ul {
    border-left: 0.75px solid #1e1b31;
    padding-right: 20px;
  }
  &&li {
    width: 100px;
  }
  && ul li {
    list-style: none;
    font-weight: 400;
    cursor: pointer;
    padding: 2.5px 5px;
    border-radius: 5px;
  }
  && ul:first-child {
    border: none;
  }
`;

export const IDEList = styled(ListItem)<{ $active: boolean }>`
  gap: 10px;
  background-color: ${(props) => props.$active && '#e3e3e3'};
  color: ${(props) => props.$active && '#000000'};
  &:hover {
    background-color: #e3e3e3;
    color: #000000;
  }
`;

export const EndInterviewLogoWrapper = styled(Box)`
  display: flex;
  align-items: center;
  gap: 25px;
`;

export const AcceptRejectButtonContainer = styled(Box)`
  display: flex;
  gap: 5px;
  justify-content: flex-end;
`;

export const AllowButton = styled.button`
  background: #28b894;
  border: 1px solid #28b894;
  width: 100px;
  height: 32px;
  border-radius: 5px;
  font-family: var(--bodyText-font-family);
  font-size: 14px !important;
  font-weight: 500;
  text-transform: capitalize;
  color: #ebebeb;
  padding: 5px 15px 5px 15px !important;
  &:hover {
    box-shadow: 0 0 10px #28b894;
  }
`;

export const DenyButton = styled.button`
  background: transparent !important;
  border: 1px solid #ff4d6a;
  width: 100px;
  height: 32px;
  border-radius: 4px;
  font-family: var(--bodyText-font-family);
  font-size: 14px !important;
  font-weight: 500;
  text-transform: capitalize;
  color: #ff4d6a;
  padding: 5px 15px 5px 15px !important;
  &:hover {
    box-shadow: 0 0 10px #ff4d6a;
  }
`;

export const VideoContainer = styled(Box)`
  display: flex;
  justify-content: center;
  position: relative;
`;

export const AudioVideoButtonContainer = styled(Box)`
  display: flex;
  gap: 20px;
  justify-content: center;
  margin: 10px;
  & svg {
    border: 2px solid white;
    background: #123;
  }
`;

export const DialogWrapper = styled(Dialog)<{ theme: string }>`
  .MuiDialog-paperWidthSm {
    padding: 0px 10px;
    border-radius: 15px;
    margin-bottom: 45px;
    font-family: var(--bodyText-font-family);
  }
`;

export const DialogContentWrapper = styled(Box)`
  font-family: var(--bodyText-font-family);
  font-size: 20px;
  font-weight: 500;
`;

export const DialogText = styled(Box)`
  padding: 16px;
  font-family: var(--bodyText-font-family);
  font-size: 14px;
`;

export const HeaderText = styled(Box)`
  padding: 8px 0px 0px 16px;
  height: 24px;
  font-family: var(--bodyText-font-family);
  color: var(--first-color);
  font-size: 16px;
`;

export const DialogActionsWrapper = styled(DialogActions)`
  display: flex;
  justify-content: flex-end !important;
  gap: 8px;
  width: 100%;
  height: 32px;
  margin-bottom: 16px !important;
`;

export const callButtonsSx: (value: string) => SxProps = (value) => ({
  maxWidth: '150px',
  whiteSpace: 'nowrap',
  backgroundColor:
    value === 'request'
      ? colors.acceptGreen
      : value === 'reject'
        ? colors.thickRed
        : colors.philippineGreen,
  border: `1px solid ${value === 'request' ? colors.acceptGreen : value === 'reject' ? colors.thickRed : colors.philippineGreen}`,
  color: colors.white,
  '&:disabled': {
    opacity: ' 0.5',
    cursor: ' not-allowed',
  },
  '&:hover': {
    border: `1px solid ${value === 'request' ? colors.acceptGreen : colors.white}`,
    backgroundColor:
      value === 'request'
        ? colors.acceptGreen
        : value === 'reject'
          ? colors.thickRed
          : colors.philippineGreen,
  },
});

export const endInterviewButtonSx: SxProps = {
  maxwidth: ' 150px',
  border: `1px solid ${colors.red}`,
  color: colors.white,
};

export const okButtonSx: SxProps = {
  color: colors.white,
  border: `2px solid ${colors.grey}`,
};

export const runResetRequestButtonSx: (value: string) => SxProps = (value) => ({
  border: `1.5px solid ${value === 'run' ? colors.green : value === 'request' ? colors.pictonBlue : value === 'revoke' ? colors.red : colors.white}`,
  color: colors.white,
  display: 'flex',
  gap: '4px',
  justifyContent: 'space-between',
  alignItems: 'center',
  '&:hover': {
    border: `1.5px solid  ${value === 'run' ? colors.green : value === 'request' ? colors.pictonBlue : value === 'revoke' ? colors.red : colors.white}`,
  },
});

export const loadingEditorSx: SxProps = {
  fontSize: '16px',
  fontWeight: 400,
  display: 'flex',
  flexDirection: 'column',
  flex: '100%',
  height: '100vh',
  justifyContent: 'center',
  alignItems: 'center',
  fontFamily: 'var(--bodyText-font-family)',
};
export const userVideoStyle = {
  width: '100%',
  height: '100%',
  borderRadius: '20px',
  border: '2px solid var(--first-color)',
  zIndex: 20,
};

export const draggingCursorSx = {
  cursor: 'all-scroll',
};
