import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
// CSS
import '../../css/assessmentCompleted.css';

// Static
import { commonStrings } from '../../static/commonStrings';
import { Box, SxProps, Typography } from '@mui/material';
import { getEnterpriseById } from '../../api/requests/enterprise';
import { setProfileDetailSettings } from '../../util/util';
import { ThemeConfigurationType } from '../Types/Index';
import { getEnterpriseTheme } from '../../api/requests/themeConfiguration';
import { getInterviewCandidate } from '../../api/requests/interview';
import ZecodeUrl from '../common/ZecodeUrl';
import { ZecodeText } from '../common/zecodeText';
import {
  FooterStyles,
  ImageStyles,
  InterviewTextStyles,
  LogoWrapper,
  StyledContainerStyles,
} from '../common/styles';
import { colors } from '../../static/constants';
import { error } from 'console';
import CandidateScreenBackground from '../common/candidateScreenBg';
import { LogoImage } from './candidateInvitation';
import BottomPanel from '../common/BottomCaptionPanel';

const zecodeLogo = '/images/zecodeSideBarLogo.svg';
const success = '/images/Success.svg';

const InterviewCompleted = () => {
  const { id } = useParams();
  const [enterpriseLogo, setEnterpriselogo] = useState('');
  const [enterpriseId, setEnterpriseId] = useState();
  const [enterpriseName, setEnterpriseName] = useState('');
  const [profileDetails, setProfileDetails] = useState<ThemeConfigurationType>();

  const fetchInterviewDetails = async () => {
    if (id) {
      const response = await getInterviewCandidate({ id });
      setEnterpriseId(response?.data?.data?.enterpriseId);
      const enterprise = await getEnterpriseById(enterpriseId!);
      setEnterpriselogo(enterprise?.data?.data?.imageURL);
      setEnterpriseName(enterprise?.data?.data?.name);
    }
  };

  const fetchProfileDetails = async () => {
    const response = await getEnterpriseTheme(enterpriseId!);
    response &&
      response?.data &&
      response?.data?.data &&
      setProfileDetailSettings(response?.data?.data);
    setProfileDetails(response?.data?.data);
  };

  useEffect(() => {
    fetchInterviewDetails();
    fetchProfileDetails();
  }, [enterpriseId]);

  return (
    <CandidateScreenBackground>
      <Box>
        <Box sx={{ position: 'fixed', left: '24px', top: '24px' }}>
          <LogoImage
            src={
              profileDetails?.imageURL
                ? profileDetails?.imageURL
                : enterpriseLogo
                  ? enterpriseLogo
                  : zecodeLogo
            }
            alt='zessta Logo'
          />
        </Box>
        <Box
          sx={{
            width: '566px',
            height: '300px',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            position: 'fixed',
            backgroundColor: colors.zecodePurple,
            borderRadius: '12px',
          }}
        >
          <Box
            sx={{
              pt: '30px',
              pl: '30px',
            }}
          >
            <img src={success} alt='error' />
          </Box>
          <Box
            sx={{
              // pt: '30px',
              pl: '30px',
            }}
          >
            <Typography fontSize={'38px'} fontWeight={900} color={colors.white}>
              Your Interview Successfully Ended
            </Typography>
            <Box maxWidth={'445.74px'}>
              <Typography fontSize={'16px'} fontWeight={400} color={colors.white}>
                Thanks for taking part in the interview with {enterpriseName}
              </Typography>
            </Box>
          </Box>
        </Box>
        <BottomPanel />
      </Box>
    </CandidateScreenBackground>
  );
};

export default InterviewCompleted;

const InterviewTextContent: SxProps = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
};

const enterpriseNameStyles: SxProps = {
  fontWeight: 500,
  paddingBottom: '30px',
};

const ThankYouTextStyles: SxProps = {
  fontFamily: 'var(--bodyText-font-family)',
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '18px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  color: colors.gray88,
  marginTop: '20px',
};

const HrStyles: SxProps = {
  width: '500px',
  height: '0px',
  marginBottom: '30px',
  border: `1px solid ${colors.gray88}`,
};
