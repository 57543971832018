import React, { useEffect, useState } from 'react';
import {
  SandpackProvider,
  SandpackPreview,
  SandpackCodeEditor,
  SandpackStack,
  SandpackLayout,
  // To do for later
  // SandpackFileExplorer,
  useSandpack,
} from '@codesandbox/sandpack-react';
import { SandpackFileExplorer } from 'sandpack-file-explorer';
import styled from 'styled-components';
import { IDE_TEMPLATES, sectionStatus } from '../../static/constants';
import { nightOwl } from '@codesandbox/sandpack-themes';
import { Box, SxProps } from '@mui/material';
import { mobileSizes } from './candidateCoding';

const SandpackCodeEditorWrapper = styled(SandpackCodeEditor)`
  .sp-c-gGbYbQ::-webkit-scrollbar-thumb {
    background-color: var(--first-color);
    border-radius: 50px;
  }
  .sp-c-gGbYbQ::-webkit-scrollbar {
    width: 2px;
    height: 5px;
  }
  .sp-c-gGbYbQ::-webkit-scrollbar-track {
    background-color: #e4e4e4;
    border-radius: 50px;
    margin-left: 10px;
  }
`;

const CodeViewer = (props: any) => {
  const { sandpack } = useSandpack();
  const { files, activeFile } = sandpack;
  props.setCodeFiles(files);
  props.setActiveFiles(activeFile);
  return <pre></pre>;
};

interface SandpackComponentProps {
  template?: string | undefined;
  activeFile?: any;
  intitalFileState?: any;
  codeFiles?: any;
  handleUpdation?: any;
  setActiveFile?: any;
  typingAccessAllowed?: boolean;
  status?: string;
  isInterviewer?: string | object;
}

const SandpackComponent: React.FC<SandpackComponentProps> = ({
  template,
  activeFile,
  intitalFileState,
  codeFiles,
  handleUpdation,
  setActiveFile,
  typingAccessAllowed,
  status,
  isInterviewer,
}) => {    

  useEffect(() => {
    const result: { [key: string]: string } = {};

    if (codeFiles) {
      Object.entries(codeFiles)?.forEach(([key, value]) => {
        result[key] = (value as { code: string })?.code;
      });
    }

    for (const [key, value] of Object.entries(result)) {
      if (
        (key === '/addFile' && value === '.emptyDir') ||
        (key === '/addFolder'  && value === '.emptyDir') ||
        (key === '/src/app/addFile'  && value === '.emptyDir') ||
        (key === '/src/app/addDir'  && value === '.emptyDir')
      ) {        
        return;
      }
    }
  }, [codeFiles]);    

  return (
    <SandpackProvider
      template={template === IDE_TEMPLATES.REACT_NATIVE ? IDE_TEMPLATES.REACT : (template as any)}
      options={{ activeFile: activeFile }}
      theme={nightOwl}
      files={intitalFileState}
      key={template}
    >
      <SandpackStack>
        <SandpackLayout>
          <Box sx={fileExplorerStyles(typingAccessAllowed!)}>
          <SandpackFileExplorer  key={codeFiles && Object.keys(codeFiles).length}/>
          </Box>
          <CodeViewer
            codeFiles={codeFiles}
            setCodeFiles={handleUpdation}
            setActiveFiles={setActiveFile}
          />
          <SandpackCodeEditorWrapper
            style={{ height: '85vh' }}
            readOnly={!typingAccessAllowed && status !== sectionStatus.submitted}
            showTabs
            showLineNumbers={true}
            showInlineErrors
            wrapContent
            closableTabs
            key={codeFiles}
          />
          {template === IDE_TEMPLATES.REACT_NATIVE ? (
            <Box
              sx={reactNativeTemplateStyles({
                height: mobileSizes[0]?.height,
                width: mobileSizes[0]?.width,
              })}
            >
              <SandpackPreview
                style={{ height: '100%', width: '100%', borderRadius: '40px' }}
                showOpenInCodeSandbox={false}
              />
            </Box>
          ) : (
            <SandpackPreview
              style={{ height: '85vh', width: '2000px' }}
              showOpenInCodeSandbox={false}
            />
          )}
        </SandpackLayout>
      </SandpackStack>
    </SandpackProvider>
  );
};

export default SandpackComponent;

const reactNativeTemplateStyles = (mobileSelectedOption: { width: number; height: number }) => {
  return {
    position: 'relative',
    margin: 'auto',
    height: 'calc(100vh - 180px)',
    border: '16px black solid',
    borderTopWidth: '60px',
    borderBottomWidth: '60px',
    borderRadius: '36px',
    m: 1,
    aspectRatio: 9 / 18,
    '&::before': {
      content: '""',
      display: 'block',
      width: '60px',
      height: '5px',
      position: 'absolute',
      top: '-30px',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      background: '#333',
      borderRadius: '10px',
    },
    '&::after': {
      content: '""',
      display: 'flex',
      width: '35px',
      height: '35px',
      position: 'absolute',
      bottom: '-65px',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      background: '#333',
      borderRadius: '50%',
    },
  };
};

const fileExplorerStyles: (typingAccessAllowed: boolean) => SxProps = (typingAccessAllowed) => ({
  '& > div': {
    height: '85vh !important',
  },
  '& button': {
    display: 'none !important',
  },
  '& > div > div': {
    color: 'white',
    '& > div': {
      '& > div': {
        display: typingAccessAllowed ? 'flex' : 'none !important',
      },
    },
    '& > ul': {
      '& > li': {
        '& > div': {
          '& > button': {
            display: 'none !important',
          },
        },
      },
    },
  },
});
