import React, { useState } from 'react';
import { Backdrop, Popover, Typography } from '@mui/material';
import styled from 'styled-components';

const StyledBackdrop = styled(Backdrop)`
  && {
    z-index: 1;
    color: #fff;
  }
`;

const StyledPopoverText = styled(Typography)`
  && {
    font-family: var(--bodyText-font-family);
    font-size: 14px;
    color: #313331;
  }
`;

const StyledPopoverDiv = styled.div`
  && {
    min-height: 40px;
    width: auto;
    background-color: #ffff;
    padding: 5px;
    border-radius: 5px;
    border: 2px solid black;
    position: fixed;
  }
  &&::after {
    content: '';
    width: 0px;
    height: 0px;
    border-top: 10px solid transparent;
    border-bottom: 10px solid #ffff;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    position: absolute;
    left: 61px;
    bottom: 100%;
  }
`;

interface IndicationPopoverProps {
  anchorEl?: Element | null | undefined;
  text: string;
  onClose: () => void;
}
const IndicationPopover: React.FC<IndicationPopoverProps> = ({ anchorEl, text, onClose }) => {
  const [open, setOpen] = useState(true);
  const handleClose = () => {
    setOpen(false);
    onClose();
  };
  return (
    <StyledBackdrop open={open} onClick={handleClose}>
      <Popover
        sx={{ top: '40px' }}
        PaperProps={{
          style: {
            backgroundColor: 'transparent',
            boxShadow: 'none',
            borderRadius: 0,
            overflow: 'hidden',
          },
        }}
        id={'new-menu'}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <StyledPopoverDiv>
          <StyledPopoverText>{text}</StyledPopoverText>
        </StyledPopoverDiv>
      </Popover>
    </StyledBackdrop>
  );
};

export default IndicationPopover;
