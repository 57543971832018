import { PrimaryCameraCheck, SecondaryMediaCheck, InstallExtension, GetExtensionsList } from "../components/common/preAssessmentStepsComponents";
import { PreAssessmentStep, featuresList } from "../static/constants";
import { IsFeatureFlagAvailable } from "./isFeatureAvailable";

export const getFilteredPreAssessmentCheckSteps = (): PreAssessmentStep[] => {
    const steps: PreAssessmentStep[] = [
        {
            key: 1,
            label: 'Enable your system camera and flash a smile for a quick face check',
            description: 'Clean your camera and brighten your space for a clear view before flashing that smile for a quick face check!',
            isFeatureNeeded: IsFeatureFlagAvailable(featuresList.ASSESSMENT_PROCTORING),
            component: PrimaryCameraCheck,
        },
        {
            key: 2,
            label: 'Connect to your secondary camera',
            description: 'Clean your lens, position the secondary camera farther for a better angle, and turn on your microphone to be heard clearly.',
            isFeatureNeeded: IsFeatureFlagAvailable(featuresList.SECONDARY_CAMERA),
            component: SecondaryMediaCheck,
        },
        {
            key: 3,
            label: 'Install the Ze[code] IntegrityGuard',
            description: 'Before installing the Ze[code] Integrity Guard extension, ensure it\'s from a trusted source, verify browser compatibility, and close any sensitive tabs.',
            isFeatureNeeded: IsFeatureFlagAvailable(featuresList.EXTENSIONS),
            component: InstallExtension,
        },
        {
            key: 4,
            label: 'Disable chrome extensions',
            description: 'Disable all unauthorized chrome extensions to avoid technical issues and distractions during the assessment.',
            isFeatureNeeded: IsFeatureFlagAvailable(featuresList.EXTENSIONS),
            component: GetExtensionsList,
        },
    ];

    return steps.filter(step => step.isFeatureNeeded);
};
