import { Box, SxProps, Theme, Typography } from '@mui/material';
import React from 'react';
import CopyrightOutlinedIcon from '@mui/icons-material/CopyrightOutlined';
import { colors } from '../../static/constants';

const BottomPanel = () => {
  const currentYear = new Date().getFullYear();

  return (
    <Box sx={BoxSx}>
      <Box display='flex' alignItems='center' flexGrow={1} gap={10}>
        <Box display='flex'>
          <CopyrightOutlinedIcon style={{ marginRight: 4 }} sx={{ color: colors.footerText }} />
          <a
            href='https://www.zecode.live/'
            target='_blank'
            style={{ textDecoration: 'none', borderBottom: `1px solid ${colors.footerText}` }}
            rel='noreferrer'
          >
            <Typography variant='body1' color={colors.footerText} display='flex'>
              {currentYear} zecode.live
            </Typography>
          </a>
        </Box>
        <Box display='flex'>
          <a
            href='https://www.zecode.live/terms-of-service/'
            target='_blank'
            style={{ textDecoration: 'none', borderBottom: `1px solid ${colors.footerText}` }}
            rel='noreferrer'
          >
            <Typography variant='body1' color={colors.footerText} display='flex'>
              Terms & Conditions
            </Typography>
          </a>
        </Box>
        <Box display='flex'>
          <a
            href='https://www.zecode.live/privacy-policy/'
            target='_blank'
            style={{ textDecoration: 'none', borderBottom: `1px solid ${colors.footerText}` }}
            rel='noreferrer'
          >
            <Typography variant='body1' color={colors.footerText} display='flex'>
              Privacy Policy
            </Typography>
          </a>
        </Box>
        <Box display='flex'>
          <a
            href='https://www.zecode.live/contact-us/'
            target='_blank'
            style={{ textDecoration: 'none', borderBottom: `1px solid ${colors.footerText}` }}
            rel='noreferrer'
          >
            <Typography variant='body1' color={colors.footerText} display='flex'>
              Contact Us
            </Typography>
          </a>
        </Box>
      </Box>
      <Box>
        <Box display='flex'>
          <Typography variant='h5' color={colors.footerText2} display='flex' alignItems='center'>
            Powered by &nbsp;
          </Typography>
          <Typography variant='h5' color={colors.footerText3} display='flex' alignItems='center'>
            ze
          </Typography>
          <Typography variant='h5' color={colors.zecodePurple} display='flex' alignItems='center'>
            [
          </Typography>
          <Typography variant='h5' color={colors.footerText3} display='flex' alignItems='center'>
            code
          </Typography>
          <Typography variant='h5' color={colors.zecodePurple} display='flex' alignItems='center'>
            ]
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default BottomPanel;

const BoxSx: SxProps<Theme> = {
  width: '100vw',
  display: 'flex',
  position: 'fixed',
  bottom: 0,
  pl: '20px',
  pr: '20px',
  pb: '10px',
  backgroundColor: 'white',
  zIndex: 1000,
  justifyContent: 'space-between',
};
