import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
// CSS
import '../../css/assessmentCompleted.css';

// Static
import { colors, sectionStatus } from '../../static/constants';
import { commonStrings } from '../../static/commonStrings';
import { Box, Typography, Divider } from '@mui/material';
import { getCandidateDetails, getSubmissionEmail } from '../../api/requests/candidate';
import { getEnterpriseById } from '../../api/requests/enterprise';
import { setProfileDetailSettings } from '../../util/util';
import { ThemeConfigurationType } from '../Types/Index';
import { getItem } from '../../util/storage';
import ZecodeUrl from '../common/ZecodeUrl';
import { zecodeLogo } from '../../static/constants';
import { getEnterpriseTheme } from '../../api/requests/themeConfiguration';
import { ThemeContext } from '../context/themeContext';
import { LogoImage } from '../candidates/candidateInvitation';
import BottomPanel from '../common/BottomCaptionPanel';
import CandidateScreenBackground from '../common/candidateScreenBg';

//Images
const Submission = '/images/submission.gif';

// To do for later
// const HelpIcon = '/images/HelpIcon.svg';

const success = '/images/Success.svg';

export const PoweredBy = styled(Box)`
  display: flex;
  margin-right: 20px;
  margin-left: auto;
`;

type testStatusType = {
  openEnd: string;
  multipleChoice: string;
  codingChallenges: string;
  comprehension: string;
};

const AssessmentCompleted = () => {
  const { id } = useParams();
  const { setThemeColor } = useContext(ThemeContext);
  const enterpriseId = getItem('enterpriseId');
  const navigate = useNavigate();
  const [profileDetails, setProfileDetails] = useState<ThemeConfigurationType>();
  const [enterpriseLogo, setEnterpriselogo] = useState('');
  const [testStatus, setTestStatus] = useState<testStatusType>({
    openEnd: '',
    multipleChoice: '',
    codingChallenges: '',
    comprehension: '',
  });
  const [totalQuestions, setTotalQuestions] = useState<any>({
    openEnd: 0,
    multipleChoice: 0,
    codingChallenges: 0,
    comprehension: 0,
  });
  const [searchParams, setSearchParams] = useSearchParams();
  const getExamSubmitted = searchParams.get('examSubmitted');

  const fetchUserDetails = async () => {
    try {
      const response = await getCandidateDetails(id!);
      const newData = { ...totalQuestions };
      newData.codingChallenges = response?.data?.data[0]?.assessmentDetails?.questions?.length;
      newData.openEnd = response?.data?.data[0]?.assessmentDetails?.openEndQuestions?.length;
      newData.multipleChoice =
        response?.data?.data[0]?.assessmentDetails?.multipleChoiceQuestions?.length;
      newData.comprehension =
        response?.data?.data[0]?.assessmentDetails?.comprehensionQuestions?.length;
      setTotalQuestions(newData);
      if (response?.data.data[0]?.testResponse?.testStatus)
        setTestStatus(response?.data.data[0]?.testResponse?.testStatus);
      else {
        const { inComplete } = sectionStatus;
        setTestStatus({
          ...testStatus,
          openEnd: inComplete,
          multipleChoice: inComplete,
          codingChallenges: inComplete,
          comprehension: inComplete,
        });
      }
    } catch (error) {
      navigate('/');
    }
  };

  const submissionMail = async () => {
    const response = await getCandidateDetails(id!);
    const enterprise = await getEnterpriseById(
      response?.data?.data[0].assessmentDetails?.enterpriseId,
    );
    const profile = await getEnterpriseTheme(
      response?.data?.data[0].assessmentDetails?.enterpriseId,
    );
    setEnterpriselogo(enterprise?.data?.data?.imageURL);
    const submissionMailResponse = await getSubmissionEmail(
      response?.data?.data[0].email,
      response?.data?.data[0].assessmentDetails?.name,
      enterprise.data?.data?.name,
      profile?.data?.data?.imageURL
        ? profile?.data?.data?.imageURL
        : enterprise?.data?.data?.imageURL
          ? enterprise?.data?.data?.imageURL
          : zecodeLogo,
      profile?.data?.data?.firstColor,
    );
    if (submissionMailResponse && submissionMailResponse?.status === 'success') {
      searchParams.delete('examSubmitted');
      setSearchParams(searchParams);
    }
  };

  const fetchProfileDetails = async () => {
    const response = await getEnterpriseTheme(enterpriseId!);
    response &&
      response?.data &&
      response?.data?.data &&
      setProfileDetailSettings(response?.data?.data);
    setThemeColor(response?.data?.data?.firstColor ?? '');
    setProfileDetails(response?.data?.data);
  };

  useEffect(() => {
    fetchUserDetails();
    enterpriseId && fetchProfileDetails();
    getExamSubmitted && submissionMail();
  }, []);

  useEffect(() => {
    const handlePopState = async () => {
      window.history.pushState(null, window.location.href);
    };
    window.history.pushState(null, window.location.href);
    window.onpopstate = () => window.history.go(1);
    window.addEventListener('popstate', handlePopState);
    return () => window.removeEventListener('popstate', handlePopState);
  });

  return (
    <CandidateScreenBackground>
      <Box>
        <Box sx={{ position: 'fixed', left: '24px', top: '24px' }}>
          <LogoImage
            src={
              profileDetails?.imageURL
                ? profileDetails?.imageURL
                : enterpriseLogo
                  ? enterpriseLogo
                  : zecodeLogo
            }
            alt='zessta Logo'
          />
        </Box>
        <Box
          sx={{
            width: '566px',
            height: '385px',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            position: 'fixed',
            backgroundColor: 'var(--first-color)',
            borderRadius: '12px',
          }}
        >
          <Box
            sx={{
              pt: '30px',
              pl: '30px',
            }}
          >
            <img src={success} alt='error' />
          </Box>
          <Box
            sx={{
              // pt: '30px',
              pl: '30px',
            }}
          >
            <Typography fontSize={'38px'} fontWeight={900} color={colors.white} pt={'20px'}>
              Your assessment is submitted successfully
            </Typography>
            <Box maxWidth={'445.74px'}>
              <Typography fontSize={'16px'} fontWeight={400} color={colors.white} pt={'40px'}>
                Thank you for participating. Your responses have been recorded and received by the
                organization
              </Typography>
            </Box>
          </Box>
        </Box>
        <BottomPanel />
      </Box>
    </CandidateScreenBackground>
  );
};

export default AssessmentCompleted;
