import React, { useState, createContext, ReactNode, FC, useEffect, useContext } from 'react';
import { getEnterpriseFeatures } from '../../api/requests/featureFlag';
import { EnterpriseContext } from './enterpriseContext';
import { getItem } from '../../util/storage';
type FeatureType = Record<string, boolean> | null;

interface FeatureFlagProviderProps {
  children: ReactNode;
}

export const FeatureFlagContext = createContext<FeatureType>(null);

export const FeatureFlagProvider: FC<FeatureFlagProviderProps> = ({ children }) => {
  const [features, setFeatures] = useState<FeatureType>(null);
  const enterpriseId = getItem('enterpriseId');

  useEffect(() => {
    // eslint-disable-next-line no-inner-declarations
    async function fetchFeatures() {
      try {
        if (enterpriseId) {
          const response = await getEnterpriseFeatures(enterpriseId);
          setFeatures(response?.data);
        }
      } catch (error) {
        setFeatures(null);
      }
    }
    fetchFeatures();
  }, [enterpriseId]);

  return <FeatureFlagContext.Provider value={features}>{children}</FeatureFlagContext.Provider>;
};
