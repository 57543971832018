import { Box, SxProps, Theme } from '@mui/material';
import React, { ReactElement } from 'react';
const bgImage = '/images/candidateScreenBg.svg';

// Define the BoxSx style object first

const CandidateScreenBackground = ({ children }: { children: ReactElement }) => {
  return <Box sx={BoxSx}>{children}</Box>;
};

export default CandidateScreenBackground;

const BoxSx: SxProps<Theme> = {
  width: '100vw',
  height: '100vh',
  backgroundImage: `url(${bgImage})`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  backgroundPosition: 'bottom',
};
