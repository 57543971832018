import * as React from 'react';
import { styled, SxProps } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import { StepIconProps } from '@mui/material/StepIcon';
import { Box, Grid, Typography } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { colors, extensionId } from '../../static/constants';
import { pleaseTurnOnCamSx, iconAndTextSx, errorTextSx } from './preAssessmentStepsComponents';
import { ThemeContext } from '../context/themeContext';
import { getEnterpriseTheme } from '../../api/requests/themeConfiguration';
import { getItem } from '../../util/storage';
import { BackListExtensionFoundType } from '../Types/Index';
import { getFilteredPreAssessmentCheckSteps } from '../../util/getPreAssessmentSteps';
import Lottie from 'lottie-react';
import { commonStrings } from '../../static/commonStrings';
interface Stepprops {
  key: number;
  label: string;
  description: string;
}

interface StepperProps {
  steps: Stepprops[];
  activeStep: number;
  setActiveStep: React.Dispatch<React.SetStateAction<number>>;
}

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      background: colors.electricGreen,
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      background: colors.electricGreen,
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    minHeight: 100,
    width: 1,
    border: 0,
    marginTop: ' -20px',
    marginBottom: '-5px',
    backgroundColor: colors.brightGray,
    borderRadius: 1,
    ...theme.applyStyles('dark', {
      backgroundColor: theme.palette.grey[800],
    }),
  },
}));

const ColorlibStepIconRoot = styled('div')<{
  ownerState: { completed?: boolean; active?: boolean };
}>(({ theme }) => ({
  backgroundColor: colors.chineseSilver,
  zIndex: 1,
  color: colors.white,
  width: 18,
  height: 18,
  display: 'flex',
  borderRadius: '50%',
  justifyContent: 'center',
  alignItems: 'flex-start',
  ...theme.applyStyles('dark', {
    backgroundColor: theme.palette.grey[700],
  }),
  variants: [
    {
      props: ({ ownerState }) => ownerState.active,
      style: {
        background: colors.cadmiumYellow,
      },
    },
    {
      props: ({ ownerState }) => ownerState.completed,
      style: {
        background: colors.electricGreen,
      },
    },
  ],
}));

function ColorlibStepIcon(props: StepIconProps) {
  const { active, completed, className } = props;
  return (
    <ColorlibStepIconRoot
      ownerState={{ completed, active }}
      className={className}
    ></ColorlibStepIconRoot>
  );
}

const CustomizedStepper = ({ steps, activeStep, setActiveStep }: StepperProps) => {
  const [blackListExtensionFound, setBlackListExtensionFound] = useState<
    BackListExtensionFoundType[]
  >([]);
  const { themeColor, setThemeColor } = useContext(ThemeContext);
  const enterpriseID = getItem('enterpriseId');
  const [isExtensionInstalled, setIsExtensionInstalled] = useState<boolean>(false);
  const [isExtensionConnected,setIsExtensionConnected]  = useState<boolean>(true)

  const getStepComponent = () => {
    const filteredSteps = getFilteredPreAssessmentCheckSteps();
    if (activeStep < filteredSteps.length) {
      const { component: StepComponent } = filteredSteps[activeStep];
      return (
        <StepComponent
          activeStep={activeStep}
          setActiveStep={setActiveStep}
          setBlackListExtensionFound={setBlackListExtensionFound}
          isExtensionInstalled={isExtensionInstalled}
          setIsExtensionConnected={setIsExtensionConnected}
          blackListExtensionFound={blackListExtensionFound}
          themeColor={themeColor}
        />
      );
    } else {
      return (
        <Box sx={pleaseTurnOnCamSx}>
          <Box sx={iconAndTextSx}>
            <Box>
              <Lottie
                animationData={require('../animations/loadingCheck.json')}
                loop={true}
                style={{ height: '90px', width: '90px' }}
              />
            </Box>
            <Typography variant='h3' sx={errorTextSx(true)}>
              {commonStrings.assessment.preAssessmentChecks.allChecksPasses}

              <Typography
                variant='body2'
                sx={{ color: colors.darkLiverGray, textAlign: 'center', marginTop: '8px' }}
              >
                {commonStrings.assessment.preAssessmentChecks.startAssessment}
              </Typography>
            </Typography>
          </Box>
        </Box>
      );
    }
  };

  const getTheme = async () => {
    const themeResponse = enterpriseID && (await getEnterpriseTheme(enterpriseID!));
    themeResponse &&
      themeResponse?.data &&
      themeResponse?.data?.data &&
      setThemeColor(themeResponse?.data?.data?.firstColor ?? '');
  };

  const installCustomExtension = () => {
    try {
      if (window.chrome && window.chrome.runtime) {
        window.chrome.runtime.sendMessage(
          extensionId,
          { type: 'getExtensions' }, // Message payload
          (response) => {
            if (chrome.runtime.lastError) {
              setIsExtensionInstalled(false);
            } else {
              setIsExtensionInstalled(true);
            }
          },
        );
      } else {
        console.error('Chrome runtime is not available');
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getTheme();
    installCustomExtension();
  }, []);

  return (
    <Grid container spacing={2} sx={{ height: '100%' }}>
      <Grid item xs={7}>
        <Box sx={{ height: '100%', width: '100%' }}>
          <Stack sx={{ width: '100%' }} spacing={4}>
            <Stepper
              activeStep={activeStep}
              connector={<ColorlibConnector />}
              orientation='vertical'
              sx={{
                '& .MuiStepConnector-root': {
                  marginLeft: 1,
                },
              }}
            >
              {steps.map((data, index) => (
                <Step key={data.label} sx={stepSx}>
                  <StepLabel
                    StepIconComponent={ColorlibStepIcon}
                    sx={{
                      '& .MuiStepLabel-label': {
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                      },
                      '& .MuiStepIcon-root': {
                        padding: 0,
                      },
                    }}
                  >
                    <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
                      {data.label === 'Install the Ze[code] IntegrityGuard' ? (
                        <Typography variant='h3'>
                          Install the{' '}
                          <span style={{ color: colors.zecodePurple }}>
                            Ze[code] IntegrityGuard
                          </span>
                        </Typography>
                      ) : (
                        <Typography variant='h3' sx={{ marginLeft: 0, color: colors.darkLiverGray }}>{data.label}</Typography>
                      )}
                    </Box>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        marginTop: '5px',
                        width: '100%',
                      }}
                    >
                      <Typography
                        variant='body1'
                        sx={{
                          position: 'absolute',
                          width: {
                            xs: '30%',
                            sm: '21%',
                            md: '30%',
                            lg: '35%',
                            xl: '34%',
                          },
                        }}
                      >
                        {data.description}
                      </Typography>
                    </Box>
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
          </Stack>
        </Box>
      </Grid>
      <Grid item xs={5}>
        {getStepComponent()}
        {blackListExtensionFound.length > 0 &&
          getFilteredPreAssessmentCheckSteps()[activeStep]?.key === 4 && (
            <Typography sx={extensionTextSx} variant='body1'>
              {blackListExtensionFound.filter((ext) => ext.isRemoved === true).length}
              {` ${blackListExtensionFound.filter((ext) => ext.isRemoved === true).length > 1 ? ' Extensions' : ' Extension'}  have been disabled`}
            </Typography>
          )}
          {!isExtensionConnected && <Typography variant='body1' sx={{color:'red',paddingLeft: '24px',paddingTop:'10px'}}>{commonStrings.assessment.preAssessmentChecks.primaryExtensionError}</Typography> }
      </Grid>
    </Grid>
  );
};

export default CustomizedStepper;

const stepSx: SxProps = {
  '& .MuiStepIcon-root': {
    color: colors.paleGray,
  },
  '& .MuiStepIcon-root.Mui-completed, & .MuiStepIcon-root.Mui-active': {
    color: colors.electricGreen,
  },
  '& .MuiStepLabel-label': {
    color: colors.darkLiverGray,
    fontWeight: 400,
    fontSize: '18px',
    fontFamily: 'var(--bodyText-font-family)',
    alignItems: 'flex-start',
  },
  '& .MuiStepLabel-label.Mui-completed, & .MuiStepLabel-label.Mui-active': {
    color: colors.darkLiverGray,
    fontWeight: 400,
    fontSize: '18px',
  },
  '& .MuiStepLabel-root': {
    padding: '0px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    gap: '10px',
  },
  '& .MuiStepLabel-iconContainer': {
    paddingRight: '30px',
  },
};

const extensionTextSx: SxProps = {
  paddingLeft: '24px',
  paddingTop: '15px',
  color: colors.darkLiverGray,
};
