import React, { useContext, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { useEffect, useState } from 'react';
import {
  Box,
  Checkbox,
  Dialog,
  Grid,
  Tooltip,
  Typography,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  Button,
  SxProps,
} from '@mui/material';
import SideNav from '../common/SideNav';
import CheckBoxOutlineBlankTwoToneIcon from '@mui/icons-material/CheckBoxOutlineBlankTwoTone';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import PlayCircleFilledWhiteOutlinedIcon from '@mui/icons-material/PlayCircleFilledWhiteOutlined';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';

// Static
import { getItem, removeItem, setItem } from '../../util/storage';
import {
  RouterNames,
  SectionDetails,
  sectionStatus,
  Languages,
  SECTIONS,
  zecodeLogo,
  StorageKeys,
  leavingTabsCount,
  examSubmitted,
  contactUsUrl,
  guidelinesVariables,
  candidateStatus,
  colors,
  featuresList,
} from '../../static/constants';
import { convertTime } from '../../util/convertTime';
import { LanguageType } from './assessment';
import { ThemeConfigurationType } from '../Types/Index';
import { commonStrings, testGuidelines } from '../../static/commonStrings';

//apiRequests
import { getCandidateDetails, updateAssessmentStatus } from '../../api/requests/candidate';
import { setTime } from '../../api/requests/assessmentTimer';
import { saveCandidateForPlagarismDetails } from '../../api/requests/plagarism';
import { setProfileDetailSettings } from '../../util/util';
import { getEnterpriseById } from '../../api/requests/enterprise';
import { getEnterpriseTheme } from '../../api/requests/themeConfiguration';
import { getNameFromEmail } from '../common/getNameFromEmail';
import rehypeKatex from 'rehype-katex';
import gfm from 'remark-gfm';
import math from 'remark-math';
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';
import ReactPlayer from 'react-player';
import useDisableInspect from '../common/useDisableInspect';
import { ThemeContext } from '../context/themeContext';
import LoadingPage from '../common/LoadingPage';
import useGetAllProjectSetups, { ProjectSetupProps } from '../hooks/useGetAllProjectSetups';
import { IsFeatureFlagAvailable } from '../../util/isFeatureAvailable';
import PersonIcon from '@mui/icons-material/Person';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import WorkIcon from '@mui/icons-material/Work';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
//Icons
const LevelIcon = '/images/LevelIcon.svg';
const RoleIcon = '/images/RoleIcon.svg';
const SkillSet = '/images/SkillSet.svg';
const TimeIcon = '/images/TimeIcon.svg';

//Videos
const assessmentVideo = '/videos/zecodeAssessmentVideo.mp4';

const Container = styled(Box)`
  display: flex;
  width: calc(100vw - 261px);
  flex-direction: column;
  min-height: 100vh;
  height: 100vh;
  ::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    width: 0px;
  }
  ::-webkit-scrollbar {
    width: 0px;
  }
`;

const MainContentWrapper = styled(Box)`
  overflow: auto;
  max-height: calc(100vh - 62px);
  ::-webkit-scrollbar-thumb {
    background-color: darkgrey;
  }
  ::-webkit-scrollbar {
    width: 0.2vw;
  }
`;

const MainContent = styled(Box)`
  padding: 10px 8.8% 10px 5%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
`;

const StyledHeader = styled(Box)`
  && {
    display: flex;
    position: sticky;
    height: 62px;
    top: 0;
    justify-content: space-between;
    align-items: center;
    background: #ffffff;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.17);
    z-index: 1;
    padding-left: 5%;
  }
`;

const Heading = styled(Typography)`
  && {
    font-family: var(--bodyText-font-family);
    font-size: 28px;
    font-weight: 600;
    line-height: 48px;
    letter-spacing: 0em;
    text-align: left;
    color: black;
  }
`;

const ScrollableContainer = styled(Box)`
  max-height: fit-content;
  overflow: auto;
  padding: 17px 29px 17px 42px;
  ::-webkit-scrollbar-thumb {
    background-color: var(--first-color);
    border-radius: 50px;
  }
  ::-webkit-scrollbar {
    width: 8px;
  }
  ::-webkit-scrollbar-track {
    background-color: #f7f7f7;
    border-radius: 50px;
    margin: 10px 0px;
  }
`;
const ScrollableContainerForPopup = styled(Box)<{ dialogData?: string }>`
  display: flex;
  flex-direction: column;
  max-height: fit-content;
  padding: ${(props) =>
    props?.dialogData === guidelinesVariables.clickHere && '38px 44px 17px 45px'};
`;

const ReactMarkdownWrapper = styled(Box)`
  && {
    font-family: var(--bodyText-font-family);
    font-size: 12px;
    font-weight: 400;
  }
  &::marker {
    font-size: 14px;
  }
  h3 {
    font-size: 14px;
    font-weight: 500;
    margin: 10px 0px;
  }
`;

const GuidelinesWrapper = styled(Box)`
  && {
    font-family: var(--bodyText-font-family);
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    color: black !important;
    text-align-last: left;
    text-align: justify;
  }
  &::marker {
    font-size: 15px;
  }
  h3 {
    font-size: 14px;
    font-weight: 600;
    margin: 8px 0px;
  }
  h2 {
    font-weight: 600;
    font-size: 1.6em;
    margin: 10px 0px 30px 0px;
  }
  h4 {
    font-weight: 400;
    font-size: 12px;
    margin-top: 20px;
  }
  h1 {
    font-weight: 600;
    font-size: 1.6em;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px 0px 30px 0px;
  }
`;

const StyledQuestionContainer = styled(Box)`
  height: fit-content;
  max-height: 271px;
  width: 100%;
  left: 355px;
  top: 578px;
  border-radius: 10px;
  border: 0.5px solid #0d2225;
  margin-top: 10px;
  box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.08);
  display: flex;
  flex-direction: column;
  && .grid-container {
    max-width: 100%;
    align-items: flex-start;
    margin-bottom: 15px;
  }
`;

const OptionsHeading = styled(Typography)`
  && {
    font-family: var(--bodyText-font-family);
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: var(--first-color);
    padding-left: 42px;
    margin-top: 10px;
    flex: 33.3%;
    display: flex;
    align-items: center;
  }
`;

const StyledFormControl = styled(FormControl)`
  && {
    margin: 10px 0px 0px 42px;
  }
`;
const OptionDetailingText = styled(Typography)`
  && {
    font-family: var(--bodyText-font-family);
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #000821;
  }
`;

const GuidelineBox = styled(Box)`
  padding: 0px 19px 23px 0px;
  margin-top: 7px;
  background: #ffffff;
  box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.08);
  border-radius: 10px;
  height: 100%;
`;

const MainWrapper = styled(Box)`
  display: flex;
`;

const ZecodeLogo = styled.img`
  margin-left: auto;
  margin-right: 60px;
  padding: 20px;
  width: 65px;
  height: 87px;
  border-radius: 50%;
`;

const CheckBoxOutlineBlankTwoToneIconWrapper = styled(CheckBoxOutlineBlankTwoToneIcon)`
  color: var(--first-color);
`;

const CheckBoxIconWrapper = styled(CheckBoxIcon)`
  color: var(--first-color);
`;

const CheckboxWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  margin-left: 10px;
`;
const CheckboxAndTextWrapper = styled(Box)`
  display: flex;
  align-items: baseline;
`;
const TextWrapper = styled.span`
  font-size: 14px;
  font-family: var(--bodyText-font-family);
  line-height: 0;
  padding: 0px;
  margin: 0px;
`;

const LinkWrapper = styled.span`
  text-decoration: underline;
  cursor: pointer;
  color: blue;
`;

const DialogWrapper = styled(Dialog)<{ dialogData?: string }>`
  && {
    margin-top: 40px;
    .MuiDialog-paperWidthSm {
      border-radius: 10px;
    }
    .MuiPaper-root {
      background-color: ${(props) =>
        props.dialogData === guidelinesVariables.clickHere ? '#ffffff' : 'transparent'};
      box-shadow: none;
      max-width: ${(props) =>
        props.dialogData === guidelinesVariables.clickHere ? '40%' : '100%'};
      overflow: auto;
      ::-webkit-scrollbar-thumb {
        background-color: var(--first-color);
        border-radius: 50px;
      }
      ::-webkit-scrollbar {
        width: 4px;
      }
      ::-webkit-scrollbar-track {
        background-color: #f7f7f7;
        border-radius: 50px;
        margin: 10px 0px;
      }
    }
  }
`;

const GuidelinesPopUp = styled(Box)<{ $dialogData?: string }>`
  display: flex;
  flex-direction: column;
  max-height: ${(props) => (props?.$dialogData === guidelinesVariables.clickHere ? '68%' : '100%')};
  max-width: 100%;
`;

const CloseIconWrapper = styled(Box)<{ dialogData: string }>`
  width: 40%;
  display: flex;
  justify-content: flex-end;
  padding: ${(props) => props.dialogData === guidelinesVariables.clickHere && '10px 10px 8px 0px'};
  cursor: pointer;
  position: ${(props) =>
    props.dialogData === guidelinesVariables.clickHere ? 'fixed' : 'absolute'};
  top: ${(props) => props.dialogData !== guidelinesVariables.clickHere && '7px'};
  right: ${(props) => props.dialogData !== guidelinesVariables.clickHere && '5px'};
`;

const ContactUsWrapper = styled.a`
  color: blue;
`;

const VideoPlayerWrapper = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`;

const StyledVideoContainer = styled(Box)`
  display: flex;
  && video {
    border-radius: 8px;
  }
`;

const VideoText = styled(Box)`
  color: #000000;
  font-size: 14px;
  font-family: var(--bodyText-font-family);
  margin-top: 20px;
  width: 100%;
  margin-left: 48px;
  display: flex;
  align-items: center;
`;

const WatchVideotext = styled.p`
  font-size: 12px;
  color: var(--button-text-color);
  font-family: var(--bodyText-font-family);
  font-weight: 500;
`;

const WatchVideoButton = styled.button`
  padding: 1px 10px !important;
  background-color: var(--first-color);
  margin-left: 5px;
`;

const WatchVideoTextWrapper = styled(Box)`
  display: flex;
  align-items: center;
  gap: 5px;
`;

export default function AssessmentDetails() {
  const allProjectSetups = useGetAllProjectSetups({});
  return (
    <>
      {!allProjectSetups?.length ? (
        <LoadingPage />
      ) : (
        <AssessmentDetailsComponent allProjectSetups={allProjectSetups} />
      )}
    </>
  );
}

const AssessmentDetailsComponent = ({
  allProjectSetups,
}: {
  allProjectSetups: ProjectSetupProps[];
}) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { setThemeColor } = useContext(ThemeContext);
  const [candidateName, setCandidateName] = useState<string>();
  const [userData, setUserData] = useState<any>();
  const [languages, setLanguages] = useState<ProjectSetupProps[]>([]);
  const [section, setSection] = useState(
    getItem('section') ? getItem('section') : SECTIONS.codingChallenges,
  );
  const [isGuidelinesChecked, setIsGuidelinesChecked] = useState<boolean>(false);
  const [isTermsChecked, setIsTermsChecked] = useState<boolean>(false);
  const [isGuidelinesPopupOpened, setIsGuidelinesPopupOpened] = useState<boolean>(false);
  const [totalQuestions, setTotalQuestions] = useState<any>({
    openEnd: 0,
    multipleChoice: 0,
    codingChallenges: 0,
    databaseQuestions: 0,
    comprehension: 0,
  });
  const [guideLinesData, setGuideLinesData] = useState<string>();
  const [profileDetails, setProfileDetails] = useState<ThemeConfigurationType>();
  const [enterpriseLogo, setEnterpriselogo] = useState('');
  const [playedSeconds, setPlayedSeconds] = useState<any>(0);
  const [dialogData, setDialogData] = useState<string>(guidelinesVariables.clickHere);
  const reactPlayerRef = useRef<any>(null);
  const isPreAssessmentFeatureFlagAvailable = IsFeatureFlagAvailable(
    featuresList.PRE_ASSESSMENT_CHECKS,
  );
  const defaultGuideLines = testGuidelines(isPreAssessmentFeatureFlagAvailable);
  const videoSeen = getItem('videoSeen');
  const isVideoSeen = videoSeen === 'true';
  const handleDisable = useDisableInspect();

  handleDisable();
  // To do for later
  const totalSections = () => {
    let count = 0;
    if (totalQuestions?.codingChallenges > 0) {
      count = count + 1;
    }
    if (totalQuestions?.multipleChoice > 0) {
      count = count + 1;
    }
    if (totalQuestions?.openEnd > 0) {
      count = count + 1;
    }
    if (totalQuestions?.databaseQuestions > 0) {
      count = count + 1;
    }
    if (totalQuestions?.comprehension > 0) {
      count = count + 1;
    }
    return count;
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setItem('section', (event.target as HTMLInputElement).value);
    setSection((event.target as HTMLInputElement).value);
  };

  let guideLinesArray = defaultGuideLines;
  if (guideLinesData) {
    guideLinesArray = guideLinesData.split('\n');
  }

  if (guideLinesArray.length > 0) {
    if (
      guideLinesArray[guideLinesArray.length - 1] === '' ||
      guideLinesArray[guideLinesArray.length - 1] === '\n'
    ) {
      guideLinesArray.pop();
    }
  }

  const updateStatus = async (params: {
    isGuidelinesChecked: boolean;
    isTermsChecked: boolean;
    isProgress?: boolean;
  }) => {
    try {
      const response = await updateAssessmentStatus(params, id!);
      return (await response?.status) === 'success';
    } catch (error) {
      console.error(error);
    }
  };

  const saveTimer = async () => {
    try {
      const timeDuration = userData?.testDuration?.split(':') ?? ['00', '00'];
      const testDurationMinutes = parseInt(timeDuration[0]) * 60 + parseInt(timeDuration[1]);
      const response = await setTime(id!, 0, convertTime(testDurationMinutes));
      return (await response?.data?.message) === 'Success';
    } catch (error) {
      console.error(error);
    }
  };

  const onClickHandler = async () => {
    if (isGuidelinesChecked && isTermsChecked) {
      try {
        localStorage.removeItem('markedForReview');
        localStorage.removeItem('showSectionPopup');
        localStorage.removeItem('detection');
        localStorage.removeItem('headingText');
        setItem('pasteCount', '0');
        if (isPreAssessmentFeatureFlagAvailable) {
          setItem('preAssessmentChecksCompleted', 'false');
          const params = {
            isGuidelinesChecked,
            isTermsChecked,
          };
          const statusResponse = await updateStatus(params);
          statusResponse && navigate(`${RouterNames.preAsseessmentChecks}/${id}`);
        } else {
          const timerResponse = await saveTimer();
          await saveCandidateForPlagarismDetails(id!);
          const params = {
            isGuidelinesChecked,
            isTermsChecked,
            inProgress: true,
          };
          const statusResponse = await updateStatus(params);
          statusResponse && timerResponse && navigate(`${RouterNames.assessment}/${id}/${null}`);
        }
      } catch (error) {
        console.error(error);
      }
    }
  };

  const onClickHereHandler = (value: string) => {
    setDialogData(value);
    setIsGuidelinesPopupOpened(true);
  };

  const fetchCandidateDetails = async () => {
    const response = await getCandidateDetails(id!);
    setCandidateName(response?.data?.data[0]?.name?response?.data?.data[0]?.name:getNameFromEmail(response?.data?.data[0]?.email));
    setUserData(response?.data?.data[0]?.assessmentDetails);
    setGuideLinesData(response?.data?.data[0]?.assessmentDetails?.guideLines);
    const newData = { ...totalQuestions };
    if (response?.data?.data[0]?.status === candidateStatus.inProgress) {
      navigate(`${RouterNames.assessment}/${id}`);
    } else if (response?.data?.data[0]?.status === sectionStatus.complete) {
      removeItem(StorageKeys.WarningCount + id);
      removeItem(StorageKeys.ProctorStatus + id);
      removeItem(leavingTabsCount);
      removeItem(StorageKeys.candidateName);
      navigate(`${RouterNames.assessmentCompleted}/${id}?${examSubmitted}`);
    }
    newData.codingChallenges = response?.data?.data[0]?.assessmentDetails?.questions?.length;
    newData.openEnd = response?.data?.data[0]?.assessmentDetails?.openEndQuestions?.length;
    newData.multipleChoice =
      response?.data?.data[0]?.assessmentDetails?.multipleChoiceQuestions?.length;
    newData.databaseQuestions =
      response?.data?.data[0]?.assessmentDetails?.databaseQuestions?.length;
    newData.comprehension =
      response?.data?.data[0]?.assessmentDetails?.comprehensionQuestions?.length;
    SectionDetails.map((section) => newData[section.key] > 0 && setItem('section', section.key));
    setTotalQuestions(newData);
    const filteredData: ProjectSetupProps[] = [];
    if (allProjectSetups.length) {
      response?.data?.data[0]?.assessmentDetails?.languages.forEach((item: number) => {
        const projectSetupData = allProjectSetups.find((ide) => ide.domainID === item);
        projectSetupData && filteredData.push(projectSetupData);
      });
    }
    setLanguages(filteredData);

    const enterprise = await getEnterpriseById(
      response?.data?.data[0].assessmentDetails?.enterpriseId,
    );
    setEnterpriselogo(enterprise?.data?.data?.imageURL);
    setItem('enterpriseId', enterprise?.data?.data?._id);
    const enterpriseID = response?.data?.data[0].assessmentDetails?.enterpriseId;
    const themeResponse = enterpriseID && (await getEnterpriseTheme(enterpriseID));
    themeResponse &&
      themeResponse?.data &&
      themeResponse?.data?.data &&
      setProfileDetailSettings(themeResponse?.data?.data);
    setThemeColor(themeResponse?.data?.data?.firstColor ?? '');
    setProfileDetails(themeResponse?.data?.data);
  };

  const handleCloseDialog = (value: string) => {
    value !== guidelinesVariables.clickHere &&
      setPlayedSeconds(Math.floor(reactPlayerRef.current.getCurrentTime()));
    setIsGuidelinesPopupOpened(false);
  };

  const readyPlayer = () => {
    playedSeconds !== 0 && reactPlayerRef.current?.seekTo(playedSeconds, 'seconds');
    setPlayedSeconds(0);
  };

  const videoEnded = () => {
    setItem('videoSeen', 'true');
  };

  useEffect(() => {
    fetchCandidateDetails();
  }, []);

  const NavbarContent = [
    {
      name: 'Candidate Name',
      icon: <PersonIcon sx={{ color: colors.white }} />,
      width: 12,
      height: 12,
      value: candidateName,
    },
    {
      name: 'Time limit',
      icon: <AccessTimeFilledIcon sx={{ color: colors.white }} />,
      width: 12,
      height: 12,
      value: userData?.testDuration,
    },
    {
      name: 'Role',
      icon: <WorkIcon sx={{ color: colors.white }} />,
      width: 12,
      height: 12,
      value: userData?.role,
    },
    // {
    //   name: 'Level',
    //   icon: LevelIcon,
    //   width: 20,
    //   height: 19,
    //   value: userData?.experienceLevel,
    // },
    {
      name: 'Skill set',
      icon: <AutoAwesomeIcon sx={{ color: colors.white }} />,
      width: 12,
      height: 12,
      value: languages.map((item) => item?.setup.title).join(', '),
    },
  ];

  const renderGuideline = (guideline: string) => {
    const guidelineWithLink = guideline.replace(/\$\$([^$]+)\$\$/g, contactUsUrl!);
    const ContactUsLink = () => {
      return (
        <ContactUsWrapper href={contactUsUrl} target='_blank' rel='noopener noreferrer'>
          contact us
        </ContactUsWrapper>
      );
    };

    return (
      <ReactMarkdown
        remarkPlugins={[gfm, math]}
        rehypePlugins={[rehypeKatex]}
        components={{ a: ContactUsLink }}
      >
        {guidelineWithLink}
      </ReactMarkdown>
    );
  };

  return (
    <MainWrapper>
      <SideNav content={NavbarContent} />
      <Container>
        <StyledHeader>
          <ZecodeLogo
            className='Logo'
            src={
              profileDetails?.imageURL
                ? profileDetails?.imageURL
                : enterpriseLogo
                  ? enterpriseLogo
                  : zecodeLogo
            }
            alt='zecode'
          />
        </StyledHeader>
        <MainContentWrapper>
          <MainContent>
            <Typography variant='h1'>
              {commonStrings.assessment.assessmentStartPage.testGuidelinesHeading}
            </Typography>
            <GuidelineBox>
              <ScrollableContainer>
                {guideLinesArray?.map((guideline: string, index: number) => (
                  <ReactMarkdownWrapper key={index}>
                    <ReactMarkdown remarkPlugins={[gfm, math]} rehypePlugins={[rehypeKatex]}>
                      {guideline}
                    </ReactMarkdown>
                  </ReactMarkdownWrapper>
                ))}
              </ScrollableContainer>
              <CheckboxWrapper>
                <CheckboxAndTextWrapper>
                  <Box>
                    <Checkbox
                      icon={<CheckBoxOutlineBlankTwoToneIconWrapper fontSize='small' />}
                      checkedIcon={<CheckBoxIconWrapper fontSize='small' />}
                      onChange={(val: any) => {
                        setIsGuidelinesChecked(val.target.checked);
                      }}
                    />
                  </Box>
                  <Box>
                    <Typography variant='body1'>
                      {commonStrings.assessment.assessmentStartPage.guidelinesAcknowledge}
                    </Typography>
                  </Box>
                </CheckboxAndTextWrapper>
                <CheckboxAndTextWrapper>
                  <Box>
                    <Checkbox
                      icon={<CheckBoxOutlineBlankTwoToneIconWrapper fontSize='small' />}
                      checkedIcon={<CheckBoxIconWrapper fontSize='small' />}
                      onChange={(val: any) => {
                        setIsTermsChecked(val.target.checked);
                      }}
                    />
                  </Box>
                  <Box>
                    <Typography variant='body1'>
                      {commonStrings.assessment.assessmentStartPage.guidelinesForImageCapture}
                      <LinkWrapper
                        onClick={() => onClickHereHandler(guidelinesVariables.clickHere)}
                      >
                        {' '}
                        {guidelinesVariables.clickHere}{' '}
                      </LinkWrapper>
                      {
                        commonStrings.assessment.assessmentStartPage
                          .guidelinesForImageCaptureSubText
                      }
                    </Typography>
                  </Box>
                </CheckboxAndTextWrapper>
              </CheckboxWrapper>
              <VideoText>
                <Typography variant='body1'>{guidelinesVariables.zecodeVideoText}</Typography>
                <Button
                  variant='contained'
                  size='medium'
                  sx={{ marginLeft: '5px' }}
                  onClick={() => onClickHereHandler(guidelinesVariables.watchVideo)}
                >
                  <WatchVideoTextWrapper>
                    {window
                      .getComputedStyle(document.documentElement)
                      .getPropertyValue('--button-text-color') === colors.black ? (
                      <PlayCircleOutlineIcon style={{ width: '16px', fill: colors.black }} />
                    ) : (
                      <PlayCircleFilledWhiteOutlinedIcon style={{ width: '16px' }} />
                    )}
                    {guidelinesVariables.watchVideo}
                  </WatchVideoTextWrapper>
                </Button>
              </VideoText>
            </GuidelineBox>
            <StyledQuestionContainer>
              <OptionsHeading>
                {commonStrings.assessment.assessmentStartPage.sectionsSubHeading}
              </OptionsHeading>
              <Grid container item xs={8} justifyContent='flex-start' className='grid-container'>
                <Grid item xs={6}>
                  <StyledFormControl>
                    <RadioGroup
                      aria-labelledby='demo-radio-buttons-group-label'
                      defaultValue='female'
                      name='radio-buttons-group'
                      value={section}
                      onChange={handleChange}
                    >
                      {commonStrings.assessment.assessmentStartPage?.sectionsList?.map(
                        ({ label, value, apikey }, index) => {
                          const userPoint: any = { ...userData };
                          return (
                            userPoint?.[apikey]?.length > 0 && (
                              <FormControlLabel
                                key={label}
                                value={value}
                                control={
                                  userPoint?.questions +
                                    userPoint?.multipleChoiceQuestions +
                                    userPoint?.openEndQuestions +
                                    userPoint?.comprehensionQuestions +
                                    userPoint?.databaseQuestions ===
                                  1 ? (
                                    <Radio checked />
                                  ) : (
                                    <Radio />
                                  )
                                }
                                label={<Typography variant='body1'>{label}</Typography>}
                              />
                            )
                          );
                        },
                      )}
                    </RadioGroup>
                  </StyledFormControl>
                </Grid>
                <Grid item xs={3}>
                  <Box sx={questionsCountSx}>
                    {userData?.multipleChoiceQuestions &&
                    userData?.multipleChoiceQuestions?.length > 0 ? (
                      <Typography sx={textStyles} variant='body1'>
                        {userData?.multipleChoiceQuestions?.length}{' '}
                        {userData.multipleChoiceQuestions.length < 2
                          ? 'Question\u00A0\u00A0'
                          : 'Questions'}
                      </Typography>
                    ) : (
                      ''
                    )}

                    {userData?.questions && userData?.questions?.length > 0 ? (
                      <Typography sx={textStyles} variant='body1'>
                        {userData?.questions?.length}{' '}
                        {userData.questions.length < 2 ? 'Question\u00A0\u00A0' : 'Questions'}
                      </Typography>
                    ) : (
                      ''
                    )}
                    {userData?.openEndQuestions && userData?.openEndQuestions?.length > 0 ? (
                      <Typography sx={textStyles} variant='body1'>
                        {userData?.openEndQuestions?.length}{' '}
                        {userData.openEndQuestions.length < 2
                          ? 'Question\u00A0\u00A0'
                          : 'Questions'}
                      </Typography>
                    ) : (
                      ''
                    )}
                    {userData?.comprehensionQuestions &&
                    userData?.comprehensionQuestions?.length > 0 ? (
                      <Typography sx={textStyles} variant='body1'>
                        {userData?.comprehensionQuestions?.length}{' '}
                        {userData.comprehensionQuestions.length < 2
                          ? 'Question\u00A0\u00A0'
                          : 'Questions'}
                      </Typography>
                    ) : (
                      ''
                    )}
                    {userData?.databaseQuestions && userData?.databaseQuestions?.length > 0 ? (
                      <Typography sx={textStyles} variant='body1'>
                        {userData?.databaseQuestions?.length}{' '}
                        {userData.databaseQuestions.length < 2
                          ? 'Question\u00A0\u00A0'
                          : 'Questions'}
                      </Typography>
                    ) : (
                      ''
                    )}
                  </Box>
                </Grid>
                <Grid item xs={3}>
                  <Box sx={questionsCountSx}>
                    {userData?.multipleChoiceQuestions &&
                    userData?.multipleChoiceQuestions.length > 0 ? (
                      <Typography sx={textStyles} variant='body1'>
                        {userData?.weightage?.multipleChoice}% weightage
                      </Typography>
                    ) : (
                      ''
                    )}
                    {userData?.questions && userData?.questions.length > 0 ? (
                      <Typography sx={textStyles} variant='body1'>
                        {userData?.weightage?.codingChallenges}% weightage
                      </Typography>
                    ) : (
                      ''
                    )}
                    {userData?.openEndQuestions && userData?.openEndQuestions.length > 0 ? (
                      <Typography sx={textStyles} variant='body1'>
                        {userData?.weightage?.openEnd}% weightage
                      </Typography>
                    ) : (
                      ''
                    )}
                    {userData?.comprehensionQuestions &&
                    userData?.comprehensionQuestions.length > 0 ? (
                      <Typography sx={textStyles} variant='body1'>
                        {userData?.weightage?.comprehension}% weightage
                      </Typography>
                    ) : (
                      ''
                    )}
                    {userData?.databaseQuestions && userData?.databaseQuestions.length > 0 ? (
                      <Typography sx={textStyles} variant='body1'>
                        {userData?.weightage?.databaseQuestions}% weightage
                      </Typography>
                    ) : (
                      ''
                    )}
                  </Box>
                </Grid>
              </Grid>
            </StyledQuestionContainer>
            <Box sx={{ width: '100%', display: 'flex', justifyContent: 'end' }}>
            { isTermsChecked && isGuidelinesChecked && isVideoSeen ? (
                  <Typography sx={{ marginTop: '10px',marginLeft:'10px'}} variant="body1">
                </Typography>
                
                ) : (
                  <Typography sx={{ color: colors.red, mt:2.5, mr:2 }} variant='body1'>
                  Watch the Demo Video to proceed.
                </Typography>                
                )}
              <Tooltip
                title={isVideoSeen === false ? 'Please watch full video to start exam' : ''}
                arrow
              >
                
                <Button
                  variant='contained'
                  size='medium'
                  onClick={onClickHandler}
                  sx={startExamSx(isTermsChecked, isGuidelinesChecked, isVideoSeen)}
                  disabled={isVideoSeen === false}
                >
                  {isPreAssessmentFeatureFlagAvailable ? 'Proceed ' : 'Start Assessment'}
                </Button>
              </Tooltip>
            </Box>
          </MainContent>
        </MainContentWrapper>
      </Container>
      <DialogWrapper
        open={isGuidelinesPopupOpened!}
        onBackdropClick={() => handleCloseDialog(dialogData)}
        dialogData={dialogData}
      >
        <GuidelinesPopUp $dialogData={dialogData}>
          {dialogData === guidelinesVariables.clickHere && (
            <CloseIconWrapper onClick={() => handleCloseDialog(dialogData)} dialogData={dialogData}>
              <HighlightOffIcon />
            </CloseIconWrapper>
          )}
          <ScrollableContainerForPopup dialogData={dialogData}>
            {dialogData === guidelinesVariables.clickHere ? (
              commonStrings.assessment.assessmentStartPage.guidelinesForImageCapturePupup?.map(
                (guideline: string, index: number) => (
                  <GuidelinesWrapper key={index}>{renderGuideline(guideline)}</GuidelinesWrapper>
                ),
              )
            ) : (
              <VideoPlayerWrapper>
                <StyledVideoContainer>
                  <ReactPlayer
                    ref={reactPlayerRef}
                    url={assessmentVideo}
                    controls
                    onReady={readyPlayer}
                    onEnded={videoEnded}
                    width='900px'
                    height='100%'
                  />
                </StyledVideoContainer>
                {dialogData !== guidelinesVariables.clickHere && (
                  <CloseIconWrapper
                    onClick={() => handleCloseDialog(dialogData)}
                    dialogData={dialogData}
                  >
                    <HighlightOffIcon />
                  </CloseIconWrapper>
                )}
              </VideoPlayerWrapper>
            )}
          </ScrollableContainerForPopup>
        </GuidelinesPopUp>
      </DialogWrapper>
    </MainWrapper>
  );
};

const startExamSx: (
  isTermsChecked: boolean,
  isGuidelinesChecked: boolean,
  isVideoSeen: boolean,
) => SxProps = (isTermsChecked, isGuidelinesChecked, isVideoSeen) => ({
  '&:hover': {
    backgroundColor:
      isTermsChecked && isGuidelinesChecked && isVideoSeen
        ? 'var(--first-color)'
        : 'grey !important',
    boxShadow:
      isTermsChecked && isGuidelinesChecked && isVideoSeen
        ? '0 0 10px var(--first-color)'
        : '0 0 10px white !important',
  },
  marginTop: '14px',
  backgroundColor:
    isTermsChecked && isGuidelinesChecked && isVideoSeen ? 'var(--first-color)' : 'grey !important',
    cursor: isTermsChecked && isGuidelinesChecked && isVideoSeen ? 'pointer' : 'not-allowed',
});

const questionsCountSx: SxProps = {
  '&&': {
    marginTop: '20px',
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    gap: '16px',
    width: 'fit-content',
  },
};
const textStyles: SxProps = {
  textAlign: 'right',
};
