import React, { useState, useCallback } from 'react';
import { Excalidraw, MainMenu } from '@excalidraw/excalidraw';
import { type ExcalidrawElement } from '@excalidraw/excalidraw/types/element/types';
import { type ExcalidrawImperativeAPI } from '@excalidraw/excalidraw/types/types';
import { sectionStatus } from '../../static/constants';
import { Box, Button } from '@mui/material';

interface ExacaliDrawProps {
  handleUpdationElements?: (elements: ExcalidrawElement[]) => void;
  typingAccessAllowed?: boolean;
  status?: string;
  initialElements?: ExcalidrawElement[];
  activeQuestion?: number;
  isInterviewer?: any;
}

const ExacaliDraw = (props: ExacaliDrawProps) => {
  const { handleUpdationElements, typingAccessAllowed, status, initialElements, activeQuestion, isInterviewer } =
    props;
  const [excalidrawAPI, setExcalidrawAPI] = useState<ExcalidrawImperativeAPI | null>(null);

  const updateScene = useCallback(() => {
    if (excalidrawAPI) {
      const updatedElements = excalidrawAPI.getSceneElements() as ExcalidrawElement[];
      console.log(updatedElements);
      handleUpdationElements?.(updatedElements);
    }
  }, [excalidrawAPI, handleUpdationElements]);

  return (
    <div style={{ height: '600px' }}>
      {typingAccessAllowed && status !== sectionStatus.submitted && (
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 1 }}>
          <Button onClick={updateScene} variant='outlined'>
            Update Scene
          </Button>
        </Box>
      )}
      <Excalidraw
        excalidrawAPI={setExcalidrawAPI}
        key={`${activeQuestion}-${initialElements?.length}`}
        initialData={{ scrollToContent: true, elements: initialElements }}
        viewModeEnabled={!typingAccessAllowed && status !== sectionStatus.submitted}
      >
        <MainMenu>
          {isInterviewer && <MainMenu.DefaultItems.LoadScene />}
          {isInterviewer && <MainMenu.DefaultItems.Export />}
          {isInterviewer && <MainMenu.DefaultItems.SaveAsImage />}
          <MainMenu.DefaultItems.ClearCanvas />
          <MainMenu.DefaultItems.ChangeCanvasBackground />
        </MainMenu>
      </Excalidraw>
    </div>
  );
};

export default ExacaliDraw;
