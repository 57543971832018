import React from 'react';
import styled from 'styled-components';

import CandidateScreenBackground from './common/candidateScreenBg';
import { colors, zecodeLogo } from '../static/constants';
import { Box, Typography } from '@mui/material';
import BottomPanel from './common/BottomCaptionPanel';

const error = '/images/Error.svg';

const LogoImage = styled.img`
  width: 60px;
  height: 60px;
`;

const CandidateNotFound = () => {
  return (
    <CandidateScreenBackground>
      <Box>
        <Box sx={{ position: 'fixed', left: '24px', top: '24px' }}>
          <LogoImage src={zecodeLogo} alt='zessta Logo' />
        </Box>
        <Box
          sx={{
            width: '566px',
            height: '283px',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            position: 'fixed',
            backgroundColor: colors.zecodePurple,
            borderRadius: '12px',
          }}
        >
          <Box
            sx={{
              pt: '30px',
              pl: '30px',
            }}
          >
            <img src={error} alt='error' />
          </Box>
          <Box
            sx={{
              // pt: '30px',
              pl: '30px',
            }}
          >
            <Typography fontSize={'38px'} fontWeight={900} color={colors.white}>
              Your link has expired!
            </Typography>
            <Box maxWidth={'445.74px'}>
              <Typography fontSize={'16px'} fontWeight={400} color={colors.white}>
                Uh-oh! looks like the assessment your trying to access has expired. Please contact
                your Admin
              </Typography>
            </Box>
          </Box>
        </Box>
        <BottomPanel />
      </Box>
    </CandidateScreenBackground>
  );
};

export default CandidateNotFound;
