import { mapDeviceStrings, detectionTypes } from '../static/constants';

export const getSingleDetectionMessages = (type: string) => {
  if (!(type in mapDeviceStrings)) return;

  const deviceMessage = mapDeviceStrings[type as keyof typeof mapDeviceStrings];

  if (deviceMessage === mapDeviceStrings.noPerson) {
    return `Ah! We can’t see you— step into the camera frame so we can catch a glimpse of you!`;
  }

  const isDisableState =
    deviceMessage === mapDeviceStrings.cameraDisabled ||
    deviceMessage === mapDeviceStrings.microphoneDisabled ||
    deviceMessage === mapDeviceStrings.noSecondaryCamera;

  const isCameraState =
    deviceMessage === mapDeviceStrings.cameraDisabled ||
    deviceMessage === mapDeviceStrings.noSecondaryCamera;

  const action = isDisableState && type !== detectionTypes.microphoneMuted ? 'Turn on' : 'Unmute';
  const visibility = isCameraState ? 'see' : 'hear';

  return `Oh no! we can't ${visibility} you. ${action} your secondary device ${deviceMessage} so we can connect.`;
};

export const getMultipleDetectionMessages = (detections: string[]) => {
  const detectionTypeSet = new Set(detections);
  if (detectionTypeSet.has(detectionTypes.noPerson) && detections.length > 1) {
    detectionTypeSet.delete(detectionTypes.noPerson);
  }
  if (detections.length === 1) {
    return getSingleDetectionMessages(detections[0]);
  } else {
    if (
      detectionTypeSet.has(detectionTypes.cameraDisabled) &&
      detectionTypeSet.has(detectionTypes.microphoneDisabled)
    ) {
      return `Ah, it seems you're off the radar! Enable your secondary device camera and microphone—We can't see or hear you.`;
    }
    if (
      detectionTypeSet.has(detectionTypes.microphoneMuted) ||
      detectionTypeSet.has(detectionTypes.microphoneDisabled)
    ) {
      return getSingleDetectionMessages(detectionTypes.microphoneDisabled);
    }
    if (
      detectionTypeSet.has(detectionTypes.cameraDisabled) &&
      detectionTypeSet.has(detectionTypes.microphoneMuted)
    ) {
      return `Ah, it seems you're off the radar! Enable your camera and unmute your microphone—We can't see or hear you.`;
    }
  }
};
