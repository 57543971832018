import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Divider, Typography, Button } from '@mui/material';
import styled from 'styled-components';

// Static
import {
  RouterNames,
  demoAssessmentId,
  zecodeLogo,
  StorageKeys,
  leavingTabsCount,
  candidateStatus,
  colors,
} from '../../static/constants';
import { candidateLandingScreenInstructionText, commonStrings } from '../../static/commonStrings';
import { ThemeConfigurationType } from '../Types/Index';
import { getCandidateDetails, updateAssessmentStatus } from '../../api/requests/candidate';
import { setProfileDetailSettings } from '../../util/util';
import { getEnterpriseById } from '../../api/requests/enterprise';
import { removeItem, setItem } from '../../util/storage';
import ZecodeUrl from '../common/ZecodeUrl';
import { getEnterpriseTheme } from '../../api/requests/themeConfiguration';
import { getNameFromEmail } from '../common/getNameFromEmail';
import { useStylesCommon } from '../common/styles';
import { setTime } from '../../api/requests/assessmentTimer';
import { convertTime } from '../../util/convertTime';
import { getPlagiarismDetails } from '../../api/requests/plagarism';
import { ZecodeText } from '../common/zecodeText';
import { ThemeContext } from '../context/themeContext';
import CandidateScreenBackground from '../common/candidateScreenBg';
import BottomPanel from '../common/BottomCaptionPanel';

const divider = '/images/Line_1.svg';
const thumb = '/images/all-the-best.svg';

// To do for later
// const HelpIconWrapper = styled.div`
//   color: #cccccc;
//   font-size: 14px;
//   font-family: var(--bodyText-font-family);
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   gap: 5px;
// `;

export const LogoImage = styled.img`
  width: 60px;
  height: 60px;
`;

const CandidateInvitation = () => {
  const { setThemeColor } = useContext(ThemeContext);
  const [userName, setUserName] = useState();
  const [userRole, setUserRole] = useState();
  const [profileDetails, setProfileDetails] = useState<ThemeConfigurationType>();
  const [enterpriseName, setEnterpriseName] = useState('');
  const { id, enterpriseId } = useParams();
  const navigate = useNavigate();
  const [enterpriseLogo, setEnterpriselogo] = useState('');
  const [candidateName, setCandidateName] = useState<string>('');
  const [candidateDetails, setCandidateDetails] = useState<any>();

  const fetchUserDetails = async () => {
    const response = await getCandidateDetails(id!);
    const candidateDetailsResponse = response?.data?.data[0];
    setCandidateDetails(candidateDetailsResponse);
    const enterprise = await getEnterpriseById(enterpriseId!);
    setEnterpriseName(enterprise?.data?.data?.name);
    setEnterpriselogo(enterprise?.data?.data?.imageURL);
    setCandidateName(getNameFromEmail(response?.data?.data[0]?.email));
    setUserName(response?.data?.data[0].name);
    setUserRole(response?.data?.data[0].assessmentDetails.role);
    try {
      if (candidateDetailsResponse?.status === candidateStatus.inProgress) {
        localStorage.removeItem('sessionToken');
        navigate(`${RouterNames.assessment}/${id}/${null}`);
      }
      const isSubmittedOrTimeExpired =
        (candidateDetailsResponse?.status === candidateStatus.submitted ||
          candidateDetailsResponse?.status === candidateStatus.notAttempted) &&
        id !== demoAssessmentId;
      if (
        (isSubmittedOrTimeExpired &&
          candidateDetailsResponse?.isTimeExtended === true &&
          candidateDetailsResponse?.extraTime > 0) ||
        (isSubmittedOrTimeExpired &&
          candidateDetailsResponse?.isTimeExtended === false &&
          candidateDetailsResponse?.extraTime === 0)
      ) {
        removeItem(StorageKeys.WarningCount + id);
        removeItem(StorageKeys.ProctorStatus + id);
        removeItem(leavingTabsCount);
        removeItem(StorageKeys.candidateName);
        navigate(`${RouterNames.assessmentCompleted}/${id}`);
      }
      if (response === undefined) {
        navigate('/');
      }
    } catch (error) {
      navigate('/');
    }
  };
  const saveTimer = async () => {
    try {
      const testDuration = candidateDetails?.extraTime;
      const result = await setTime(id!, testDuration, convertTime(testDuration));
      return result?.data?.status === 'Success';
    } catch (error) {
      console.error(error);
    }
  };

  const updateStatus = async () => {
    try {
      const params = {
        inProgress: true,
        timeSpent: candidateDetails?.timeSpent,
        markedForReview: candidateDetails?.markedForReview,
      };
      const result = await updateAssessmentStatus(params, id!);
      return result?.status === 'success';
    } catch (error) {
      console.error(error);
    }
  };

  const fetchPlagiarismDetails = async () => {
    try {
      const plagiarismDetails = await getPlagiarismDetails(id!);
      setItem('pasteCount', plagiarismDetails?.data?.data[0]?.ctrlV);
      setItem(
        StorageKeys.WarningCount + id,
        plagiarismDetails?.data?.data[0]?.movementDetectionCount,
      );
      setItem(`markedForReview`, JSON.stringify(candidateDetails?.markedForReview));
      return plagiarismDetails?.data?.status === 'success';
    } catch (error) {
      console.log(error);
    }
  };
  const onClickProceedHandler = async () => {
    localStorage.setItem('currentQuestionIndex', '-1');
    localStorage.removeItem('sessionToken');
    try {
      if (
        (candidateDetails?.status === candidateStatus.submitted ||
          candidateDetails?.status === candidateStatus.notAttempted) &&
        candidateDetails?.extraTime > 0 &&
        candidateDetails?.isTimeExtended === false
      ) {
        const plagarismResult = await fetchPlagiarismDetails();
        const statusResult = await updateStatus();
        const timerResult = await saveTimer();
        plagarismResult &&
          statusResult &&
          timerResult &&
          navigate(`${RouterNames.assessment}/${id}/${null}`);
      } else {
        navigate(`${RouterNames.assessmentDetails}/${id}`);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const fetchProfileDetails = async () => {
    const response = await getEnterpriseTheme(enterpriseId!);

    response &&
      response?.data &&
      response?.data?.data &&
      setProfileDetailSettings(response?.data?.data);
    setThemeColor(response?.data?.data?.firstColor ?? '');
    setProfileDetails(response?.data?.data);
  };

  useEffect(() => {
    fetchProfileDetails();
    fetchUserDetails();
    setItem('enterpriseId', enterpriseId!);
    removeItem('preAssessmentChecksCompleted');
  }, []);

  return (
    <CandidateScreenBackground>
      <Box>
        <Box sx={{ position: 'fixed', left: '24px', top: '24px' }}>
          <LogoImage
            src={
              profileDetails?.imageURL
                ? profileDetails?.imageURL
                : enterpriseLogo
                  ? enterpriseLogo
                  : zecodeLogo
            }
            alt='zessta Logo'
          />
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            position: 'fixed',
            left: '150px',
            top: '50%',
            transform: 'translateY(-50%)',
            maxWidth: '445px',
          }}
        >
          <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', mb: '16px' }}>
            <Typography fontSize={'38px'} fontWeight={500} color='var(--first-color)'>
              All the best
            </Typography>
            <img src={thumb} alt='thumb' />
          </Box>
          <img src={divider} alt='divider' />
          <Box sx={{ mt: '14.7px', mb: 1 }}>
            <Typography fontSize={'14px'} fontWeight={500} color='var(--first-color)'>
              Important
            </Typography>
          </Box>
          <Box>
            <Typography fontSize={'16px'} fontWeight={400} color={colors.mediumGray}>
              {candidateLandingScreenInstructionText}
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            position: 'fixed',
            right: '150px',
            top: '50%',
            transform: 'translateY(-50%)',
            minWidth: '566px',
            minHeight: '431.75px',
            maxWidth: '566px',
            maxHeight: '431.75px',
            backgroundColor: 'var(--first-color)',
            borderRadius: '12px',
          }}
        >
          <Box
            sx={{
              pl: '50px',
              pr: '50px',
              pt: '80px',
              display: 'flex',
              flexDirection: 'column',
              gap: 1,
            }}
          >
            <Typography fontSize={'38px'} fontWeight={900} color={colors.white}>
              Hey {userName ? userName : candidateName}!
            </Typography>
            <Typography fontSize={'24px'} fontWeight={400} color={colors.white}>
              Welcome to {enterpriseName},
            </Typography>
            <Typography fontSize={'24px'} fontWeight={300} color={colors.white}>
              You’ve been invited to take a test for the role of {userRole}
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', width: '100%', justifyContent: 'center', pt: 10 }}>
            <Button
              onClick={onClickProceedHandler}
              sx={{
                width: '375px',
                height: '54px',
                backgroundColor: colors.white,
                color: 'var(--first-color)',
                '&:hover': {
                  backgroundColor: colors.white,
                  boxShadow: 'none',
                },
              }}
            >
              <Typography variant='h3'>Click to proceed</Typography>
            </Button>
          </Box>
        </Box>
        <BottomPanel />
      </Box>
    </CandidateScreenBackground>
  );
};

export default CandidateInvitation;

export const proceedButtonSx = {
  width: '100%',
  margin: 0,
  p: 1,
  fontSize: '14px',
};
