import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useNavigate, useParams } from 'react-router-dom';
import { colors, RouterNames, sectionStatus, zecodeLogo } from '../../static/constants';

// CSS

import '../../css/assessmentCompleted.css';

// Static
import { commonStrings } from '../../static/commonStrings';
import { Box, Button, SxProps, Typography } from '@mui/material';
import { getInterviewCandidate, updateInterviewStatus } from '../../api/requests/interview';
import { ThemeConfigurationType } from '../Types/Index';
import { setProfileDetailSettings } from '../../util/util';
import { getEnterpriseById } from '../../api/requests/enterprise';
import { getEnterpriseTheme } from '../../api/requests/themeConfiguration';
import ZecodeUrl from '../common/ZecodeUrl';
import { ZecodeText } from '../common/zecodeText';
import { ThemeContext } from '../context/themeContext';
import CandidateScreenBackground from '../common/candidateScreenBg';
import { LogoImage } from './candidateInvitation';
import BottomPanel from '../common/BottomCaptionPanel';

//Images
const BackgroundImage = '/images/interviewsBackground.svg';
const thumb = '/images/all-the-best.svg';

const InterviewDetails = () => {
  const { setThemeColor } = useContext(ThemeContext);
  const { id } = useParams();
  const navigate = useNavigate();

  const [profileDetails, setProfileDetails] = useState<ThemeConfigurationType>();
  const [enterpriseLogo, setEnterpriselogo] = useState('');
  const [enterpriseName, setEnterpriseName] = useState('');
  const [candidateName, setCandidateName] = useState('');
  const [interviewStarted, setInterViewStarted] = useState<boolean>(false);
  const [isCamera, setIsCamera] = useState<boolean>(false);
  const [date, setDate] = useState<string>('');

  const updateStatus = async () => {
    try {
      id && updateInterviewStatus({ id, status: sectionStatus.inProgress });
    } catch (error) {
      console.error(error);
    }
  };

  const onClickProceedHandler = async () => {
    try {
      if (id) {
        const response = await getInterviewCandidate({ id });
        const isCandidateActive = response?.data?.data?.isActive;

        if (isCandidateActive === true) {
          await updateStatus();
          window.open(`${RouterNames.interview}/${id}`, '_blank');
        } else {
          navigate('/');
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const fetchCandidateDetails = async () => {
    try {
      if (id) {
        const response = await getInterviewCandidate({ id });
        const enterpriseID = response?.data?.data?.enterpriseId;
        setCandidateName(response?.data?.data?.name);
        const themeResponse = enterpriseID && (await getEnterpriseTheme(enterpriseID));
        themeResponse &&
          themeResponse?.data &&
          themeResponse?.data?.data &&
          setProfileDetailSettings(themeResponse?.data?.data);
        setThemeColor(themeResponse?.data?.data?.firstColor ?? '');
        setProfileDetails(themeResponse?.data?.data);
        const currentDateTime = new Date();
        const inputDateTimeString = response.data?.data?.scheduledDateTime;
        const inputDateTime = new Date(inputDateTimeString);

        const formattedDateWithMonthName = inputDateTime.toLocaleDateString('en-US', {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
          hour12: true,
        });
        setDate(formattedDateWithMonthName);

        if (inputDateTime <= currentDateTime) {
          setInterViewStarted(true);
        }
        if (response?.data?.data?.status === sectionStatus.submitted || response?.data?.data?.status === sectionStatus.cancelled) {
          navigate('/');
        }
        const enterprise = await getEnterpriseById(response?.data?.data?.enterpriseId);
        setEnterpriselogo(enterprise?.data?.data?.imageURL);
        setEnterpriseName(enterprise?.data?.data?.name);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchCandidateDetails();
  }, []);

  useEffect(() => {
    const checkCamera = async () => {
      try {
        const details = await navigator.mediaDevices.enumerateDevices();
        const detailsData = details.find((obj: any) => obj.kind === 'videoinput');
        if (!detailsData?.deviceId || !detailsData?.groupId) {
          setIsCamera(false);
        } else {
          setIsCamera(true);
        }
      } catch (error) {
        console.error(error);
      }
    };
    checkCamera();
  }, []);

  return (
    <CandidateScreenBackground>
      <Box>
        <Box sx={{ position: 'fixed', left: '24px', top: '24px' }}>
          <LogoImage
            src={
              profileDetails?.imageURL
                ? profileDetails?.imageURL
                : enterpriseLogo
                  ? enterpriseLogo
                  : zecodeLogo
            }
            alt='zessta Logo'
          />
        </Box>
        <Box
          sx={{
            width: '566px',
            height: '334px',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -70%)',
            position: 'fixed',
            backgroundColor: 'var(--first-color)',
            borderRadius: '12px',
          }}
        >
          <Box
            sx={{
              pl: '50px',
              pr: '50px',
              pt: '50px',
              display: 'flex',
              flexDirection: 'column',
              gap: 1,
            }}
          >
            <Typography fontSize={'28px'} fontWeight={900} color={colors.white}>
              Hey {candidateName}!
            </Typography>
            <Typography fontSize={'24px'} fontWeight={300} color={colors.white}>
              {interviewStarted
                ? `You have been invited to attend an interview with ${enterpriseName}, Please use the
              link below to attend the interview`
                : `You have been invited to attend an interview
              with ${enterpriseName},`}
            </Typography>
            <Box sx={{ display: 'flex', width: '100%', justifyContent: 'center', pt: 5 }}>
              <Button
                onClick={onClickProceedHandler}
                disabled={!interviewStarted}
                sx={{
                  width: '425px',
                  height: '54px',
                  backgroundColor: colors.white,
                  color: 'var(--first-color)',
                  '&:hover': {
                    backgroundColor: colors.white,
                    boxShadow: 'none',
                  },
                }}
              >
                <Typography variant='h3'>
                  {' '}
                  {interviewStarted ? 'Click to join Interview' : `Interview starts on ${date}`}
                </Typography>
              </Button>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            mb: '16px',
            top: '80%',
            left: '50%',
            transform: 'translate(-50%, -100%)',
            position: 'fixed',
          }}
        >
          <Typography fontSize={'38px'} fontWeight={500} color='var(--first-color)'>
            All the best
          </Typography>
          <img src={thumb} alt='thumb' />
        </Box>
        <BottomPanel />
      </Box>
    </CandidateScreenBackground>
  );
};

export default InterviewDetails;

const logoSx: SxProps = {
  borderRadius: '40px',
  width: '65px',
  height: '65px',
};

const interviewTextContentSx: SxProps = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
};

const enterpriseNameSx: SxProps = {
  fontWeight: 500,
  paddingBottom: '30px',
};

const interviewProceedButtonSx: SxProps = {
  width: '20%',
  marginTop: '14px',
  fontSize: '14px',
  color: colors.white,
  textTransform: 'inherit',
  padding: '4px 0px',
};

const containerSx: SxProps = {
  height: '100vh',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  padding: '30px',
  backgroundImage: `url(${BackgroundImage})`,
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
};

const logoWrapperSx: SxProps = {
  display: 'flex',
  marginRight: '80px',
  marginTop: ' 20px',
  marginLeft: '38px',
};
