import React from 'react';

import { Box, Skeleton } from '@mui/material';
import { colors, SectionDetails } from '../../static/constants';
import { EachSection, StyledEachTab, StyledQuestionsDiv } from './assessment';

const SidePanelLoader = () => {
  return (
    <>
      {SectionDetails.map((sectionDetail, ind) => (
        <StyledEachTab key={ind}>
          <EachSection style={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ width: '22px', height: '35px', mr: '10px' }}>
              <Skeleton width='100%' height='100%' sx={{ bgcolor: colors.shadowedPlum }} />
            </Box>
            <Skeleton width='195px' height='35px' sx={{ bgcolor: colors.shadowedPlum }} />
          </EachSection>
          <StyledQuestionsDiv $background={false}>
            {Array.from({ length: 8 }).map((_, index) => (
              <Box key={index} sx={questionBox}>
                <Skeleton
                  key={index}
                  width='22px'
                  height='35px'
                  sx={{ bgcolor: colors.shadowedPlum }}
                />
              </Box>
            ))}
          </StyledQuestionsDiv>
        </StyledEachTab>
      ))}
    </>
  );
};

export default SidePanelLoader;

const questionBox = {
  width: '22px',
  height: '22px',
  borderRadius: '5px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexWrap: 'wrap',
  gap: '10px',
  marginLeft: '8px',
  cursor: 'pointer',
  fontSize: '14px',
  fontWeight: 300,
};
