import { useContext } from 'react';
import { FeatureFlagContext } from '../components/context/featureFlagContext';
import { featuresList } from '../static/constants';
export const IsFeatureFlagAvailable = (featureName: string | undefined) => {
  const featureFlag = useContext(FeatureFlagContext);

  if (!featureName) {
    return true;
  }

  // Check if featureName exists as a key in featuresList object
  if (featureName in featuresList) {
    return featureFlag?.[featureName] ?? false;
  } else {
    return true;
  }
};
