import React, { useEffect, useState } from 'react';
import { Typography, DialogContentText } from '@mui/material';
import { commonStrings } from '../../static/commonStrings';
import { baseUrl, colors } from '../../static/constants';
import io from 'socket.io-client';
import { useParams } from 'react-router';
import ZecodeDialog from './zecodeDialog';
import { fetchCameraLastActiveTime } from '../../api/requests/candidate';
import { getMultipleDetectionMessages } from '../../util/getMessage';

const socket = io(baseUrl!);

const noSecondaryCameraMessage = 'Secondary camera not available';
const noSecondaryCameraDetection = 'noSecondaryCamera';

const SecondaryCameraDialog: React.FC = () => {
  const { id } = useParams();
  const [headingTexts, setHeadingTexts] = useState<string[]>(() => {
    const storedHeading = localStorage.getItem('headingText');
    return storedHeading ? JSON.parse(storedHeading) : [];
  });

  const [detections, setDetections] = useState<string[]>(() => {
    const storedDetection = localStorage.getItem('detection');
    return storedDetection ? JSON.parse(storedDetection) : [];
  });

  const compareSecondaryCameraActiveTime = async (id: string) => {
    const response = await fetchCameraLastActiveTime(id);
    if (response.lastActiveTime) {
      const time = new Date(response.lastActiveTime);
      const timestampNow = Date.now();
      const differenceInMilliSeconds = Math.abs(timestampNow - time.getTime());
      if (differenceInMilliSeconds > 30000) {
        setDetections((prevDetection) => {
          const updatedDetection = prevDetection.includes(noSecondaryCameraDetection)
            ? prevDetection
            : [noSecondaryCameraDetection];
          localStorage.setItem('detection', JSON.stringify(updatedDetection));
          return updatedDetection;
        });

        setHeadingTexts((prevHeading) => {
          const updatedHeading = prevHeading.includes(noSecondaryCameraMessage)
            ? prevHeading
            : [noSecondaryCameraMessage];
          localStorage.setItem('headingText', JSON.stringify(updatedHeading));
          return updatedHeading;
        });
      }
    }
  };

  useEffect(() => {
    socket.on('connection', () => {
      /**/
    });

    socket.on(
      `emitDetectionAlert/${id}`,
      (data: { detectionMessage: string; detectionType: string; negativeDetection: boolean }) => {
        if (data.negativeDetection) {
          setDetections((prevDetection) => {
            const updatedDetection = prevDetection.includes(data.detectionType)
              ? prevDetection
              : [...prevDetection, data.detectionType];
            localStorage.setItem('detection', JSON.stringify(updatedDetection));
            return updatedDetection;
          });

          setHeadingTexts((prevHeading) => {
            const updatedHeading =
              data.detectionMessage && !prevHeading.includes(data.detectionMessage)
                ? [...prevHeading, data.detectionMessage]
                : prevHeading;
            localStorage.setItem('headingText', JSON.stringify(updatedHeading));
            return updatedHeading;
          });
        } else {
          setDetections((prevDetection) => {
            const updatedDetection = prevDetection.filter((item) => item !== data.detectionType);
            localStorage.setItem('detection', JSON.stringify(updatedDetection));
            return updatedDetection;
          });

          setHeadingTexts((prevHeading) => {
            const updatedHeading = prevHeading.filter((item) => item !== data.detectionMessage);
            localStorage.setItem('headingText', JSON.stringify(updatedHeading));
            return updatedHeading;
          });
        }
      },
    );

    socket.on(
      `secondaryCameraActive/${id}`,
      (data: { detectionMessage: string; detectionType: string }) => {
        setDetections((prevDetection) => {
          const updatedDetection = prevDetection.filter((item) => item !== data.detectionType);
          localStorage.setItem('detection', JSON.stringify(updatedDetection));
          return updatedDetection;
        });

        setHeadingTexts((prevHeading) => {
          const updatedHeading = prevHeading.filter((item) => item !== data.detectionMessage);
          localStorage.setItem('headingText', JSON.stringify(updatedHeading));
          return updatedHeading;
        });
      },
    );
  }, [id]);

  useEffect(() => {
    if (id) {
      const intervalId = setInterval(async () => {
        await compareSecondaryCameraActiveTime(id);
      }, 60000);
      return () => clearInterval(intervalId);
    }
  }, [id]);

  return (
    <>
      {detections.length > 0 ? (
        <ZecodeDialog title={commonStrings.popUp.popupTitles.attention} hideActionButtons={true}>
          <DialogContentText sx={dialogContextSx}>
            <Typography variant='h5' sx={{ color: colors.midnightIndigo }}>
              {getMultipleDetectionMessages(detections)}
            </Typography>
          </DialogContentText>
        </ZecodeDialog>
      ) : null}
    </>
  );
};

export default SecondaryCameraDialog;

const dialogContextSx = {
  display: 'flex',
  flexDirection: 'column',
  gap: 3,
};
