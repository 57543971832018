import React, { useState, createContext, ReactNode, FC, useEffect } from 'react';
import { getEnterpriseDetailsByName } from '../../api/requests/enterprise';
type EnterpriseDetails = Record<string, any> | null;

interface EnterpriseDetailsProviderProps {
  children: ReactNode;
}

export const EnterpriseContext = createContext<EnterpriseDetails>(null);

export const EnterpriseDetailsProvider: FC<EnterpriseDetailsProviderProps> = ({ children }) => {
  const [enterpriseDetails, setEnterpriseDetails] = useState<EnterpriseDetails>(null);
  useEffect(() => {
    async function getenterpriseData() {
      const slug = localStorage.getItem('slug')!;
      const response = await getEnterpriseDetailsByName(slug);
      setEnterpriseDetails(response.data);
    }
    getenterpriseData();
  }, []);
  return (
    <EnterpriseContext.Provider value={enterpriseDetails}>{children}</EnterpriseContext.Provider>
  );
};
