import axios from 'axios';
import { baseUrl } from '../../static/constants';
import { getData } from '../apiUtils';
import { client } from '../client';
import Endpoints from '../Endpoints';

export const getEnterpriseFeaturesFlagData = (enterpriseId: string, searchFeature: string) => {
  return client
    .get(
      `${baseUrl}${Endpoints.getEnterpriseFeaturesFlagData}/${enterpriseId}?searchFeature=${searchFeature}`,
    )
    .then(getData);
};

export const getEnterpriseFeatures = (enterpriseId: string) => {
  return client.get(`${baseUrl}${Endpoints.getEnterpriseFeatures}/${enterpriseId}`).then(getData);
};
