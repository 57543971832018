import { getData } from '../apiUtils';
import { client } from '../client';
import Endpoints from '../Endpoints';

export const fetchMediaDetections = ({
  candidateId,
  camera,
  microphone,
}: {
  candidateId: string;
  camera: boolean;
  microphone: boolean;
}) => {
  return client
    .post(`${Endpoints.fetchMediaDetections}`, {
      candidateId,
      camera,
      microphone,
    })
    .then(getData);
};

export const recordAudio = ({ candidateId, record }: { candidateId: string; record: boolean }) => {
  return client
    .post(`${Endpoints.recordAudio}`, {
      candidateId,
      record,
    })
    .then(getData);
};
