import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Box, SxProps, Typography } from '@mui/material';

import Lottie from 'lottie-react';
import {
  baseUrl,
  cameraUrl,
  colors,
  detectionTypes,
  errorStepText,
  extensionId,
  extensionsText,
  extensionsToBeBlacklisted,
  extensionUrl,
  modelUrl,
} from '../../static/constants';
import io from 'socket.io-client';
import { setItem } from '../../util/storage';
import { useParams } from 'react-router-dom';
import { fetchMediaDetections, recordAudio } from '../../api/requests/preAssessmentCheck';
import * as faceapi from 'face-api.js';
import Webcam from 'react-webcam';
import { BackListExtensionFoundType } from '../Types/Index';
import { commonStrings } from '../../static/commonStrings';
import { getMultipleDetectionMessages } from '../../util/getMessage';

const disabledCameraIcon = '/images/disableCamera.svg';
const secondaryCameraIcon = '/images/secondaryCamera.svg';
const microPhoneIcon = '/images/microphone.svg';
const noExtensionsToBlock = '/images/noExtensionsToBlock.svg';
const groupIcon = '/images/Group.svg';

const socket = io(baseUrl!);

export const SecondaryMediaCheck = ({
  activeStep,
  setActiveStep,
}: {
  activeStep: number;
  setActiveStep: (value: number) => void;
}) => {
  const { id } = useParams();
  const [detections, setDetections] = useState<string[]>([
    detectionTypes.cameraDisabled,
    detectionTypes.noPerson,
  ]);

  socket.on(
    `emitDetectionAlert/${id}`,
    (data: { detectionMessage: string; detectionType: string; negativeDetection: boolean }) => {
      if (!data.negativeDetection) {
        setDetections((prevDetection) => {
          const updatedDetection = prevDetection.filter((item) => item !== data.detectionType);
          return updatedDetection;
        });
      }
    },
  );

  const checkCameraMedia = async (candidateId: string) => {
    await fetchMediaDetections({ candidateId, camera: true, microphone: false });
  };

  useEffect(() => {
    id && checkCameraMedia(id);
  }, []);

  return (
    <>
      {!detections.includes(detectionTypes.cameraDisabled) ? (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '15px', height: '100%' }}>
          <Box
            sx={{
              width: '100%',
              height: '100%',
            }}
          >
            <iframe
              src={`${cameraUrl}/${id}`}
              id={id}
              allow='autoplay; camera; microphone'
              title={id}
              style={webCamStyles}
            />
          </Box>

          <Typography variant='h4' sx={{ color: colors.red, textAlign: 'center', pb: '14px' }}>
            {getMultipleDetectionMessages(detections)}
          </Typography>

          {detections.length === 0 && (
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <SecondaryMicrophoneCheck activeStep={activeStep} setActiveStep={setActiveStep} />
            </Box>
          )}
        </Box>
      ) : (
        <Box sx={pleaseTurnOnCamSx}>
          <Box sx={iconAndTextSx}>
            <Box>
              <img src={secondaryCameraIcon} alt='icon' />
            </Box>
            <Typography variant='h3' sx={errorTextSx(false)}>
              {errorStepText.secondaryCamera}
            </Typography>
          </Box>
        </Box>
      )}
    </>
  );
};

export const SecondaryMicrophoneCheck = ({
  activeStep,
  setActiveStep,
}: {
  activeStep: number;
  setActiveStep: (value: number) => void;
}) => {
  const { id } = useParams();
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const [detections, setDetections] = useState<string[]>([
    detectionTypes.microphoneDisabled,
    detectionTypes.microphoneMuted,
  ]);
  const [isListening, setIsListening] = useState<boolean>(false);
  const [decibel, setDecibel] = useState<number>(0);

  socket.on(
    `emitDetectionAlert/${id}`,
    (data: { detectionMessage: string; detectionType: string; negativeDetection: boolean }) => {
      if (!data.negativeDetection) {
        setDetections((prevDetection) => {
          const updatedDetection = prevDetection.filter((item) => item !== data.detectionType);
          return updatedDetection;
        });
      }
    },
  );

  socket.on(`updateDecibel/${id}`, (data: { decibel: number }) => {
    setDecibel(data.decibel);
  });

  const checkMicrophoneMedia = async (candidateId: string) => {
    await fetchMediaDetections({ candidateId, camera: false, microphone: true });
  };
  const goToNextStep = useCallback(() => {
    setTimeout(() => {
      if (detections.length === 0) {
        setActiveStep(activeStep + 1);
      }
    }, 4000);
  }, [activeStep, detections.length, setActiveStep]);

  const startStopRecord = async (id: string, record: boolean) => {
    await recordAudio({ candidateId: id, record });
    if (record) {
      setIsListening(true);
    } else {
      goToNextStep();
    }
  };

  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    const ctx = canvas.getContext('2d');
    if (!ctx) return;

    let animationFrameId: number;

    const barSpacing = 3;

    const drawBars = () => {
      const width = canvas.width;
      const height = canvas.height;

      const barWidth = 5; // Fixed width for each bar
      const maxBars = Math.floor(width / barWidth); // Maximum number of bars that can fit in the canvas
      const numBars = Math.min(decibel * 1, maxBars); // The number of bars is based on decibel level but capped by the maximum that can fit
      const color = colors.zecodePurple;

      // Clear the canvas before drawing new bars
      ctx.clearRect(0, 0, width, height);

      // Loop to draw each bar with random heights (above and below the X-axis)
      for (let i = 0; i < numBars; i++) {
        const barHeight = ((Math.random() * 0.5 + 0.1) * height) / 5; // Random height between 0 and half canvas height
        // const color = getColorForPosition(i);

        // Set the bar color based on decibel level
        ctx.fillStyle = color;

        // Draw each bar above the X-axis
        ctx.fillRect(i * (barWidth + barSpacing), height / 2 - barHeight, barWidth, barHeight);
        // -2 for spacing between bars

        // Draw the same bar below the X-axis (mirrored)
        ctx.fillRect(i * (barWidth + barSpacing), height / 2, barWidth, barHeight);
      }

      // Call drawBars recursively for smooth animation
      animationFrameId = requestAnimationFrame(drawBars);
    };

    // Start the animation
    drawBars();

    // Cleanup on component unmount
    return () => {
      cancelAnimationFrame(animationFrameId);
    };
  }, [decibel]);

  useEffect(() => {
    if (detections.length === 0) {
      if (id) {
        startStopRecord(id, true);

        const timer = setTimeout(() => {
          startStopRecord(id, false);
        }, 10000); // 10 seconds

        return () => clearTimeout(timer);
      }
    }
  }, [detections.length, id]);

  useEffect(() => {
    id && checkMicrophoneMedia(id);
  }, []);

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
      <Box sx={{ ...iconAndTextSx, gap: 1.5 }}>
        {isListening ? (
          <>
            <Typography sx={{ fontSize: 14 }}>
              {commonStrings.assessment.preAssessmentChecks.audioCheckNote}
            </Typography>
            <Typography sx={{ fontSize: 16, fontWeight: 600 }}>
              {commonStrings.assessment.preAssessmentChecks.audioCheckReadingText}
            </Typography>
            <canvas
              ref={canvasRef}
              style={{ width: '100%', objectFit: 'contain', aspectRatio: 3 / 1 }}
            />
          </>
        ) : (
          <>
            <img src={microPhoneIcon} alt='microphone' />
            <Typography variant='h4' sx={{ color: colors.red, textAlign: 'center', pb: '14px' }}>
              {getMultipleDetectionMessages(detections)}
            </Typography>
          </>
        )}
      </Box>
    </Box>
  );
};

export const GetExtensionsList = ({
  blackListExtensionFound,
  themeColor,
  activeStep,
  setActiveStep,
  setBlackListExtensionFound,
  setIsExtensionConnected,
}: {
  blackListExtensionFound: BackListExtensionFoundType[];
  themeColor: string;
  activeStep: number;
  setActiveStep: (value: number) => void;
  setBlackListExtensionFound: (value: BackListExtensionFoundType[]) => void;
  setIsExtensionConnected: (value:boolean) => void;
}) => {
  const fetchExtensionsInfo = () => {
    const fetchExtensionsList = () => {
      try {
        if (window.chrome && window.chrome.runtime) {
          window.chrome.runtime.sendMessage(
            extensionId,
            { type: 'getExtensions' }, // Message payload
            (response) => {
              if (chrome.runtime.lastError) {
                console.error('Error:', chrome.runtime.lastError.message);
                setIsExtensionConnected(false)
              } else {
                setIsExtensionConnected(true)
                const extensionsFound = response?.filter((extension: any) =>
                  extensionsToBeBlacklisted.includes(extension.id),
                );
                const extensionsToBeBlock = blackListExtensionFound.map(
                  (ext: BackListExtensionFoundType) =>
                    extensionsFound.some(
                      (listExt: any) => listExt.id === ext.id && listExt.enabled === true,
                    )
                      ? ext
                      : { ...ext, isRemoved: true },
                );

                setBlackListExtensionFound(extensionsToBeBlock);
                if (extensionsToBeBlock.length === 0) {
                  clearInterval(intervalId);
                  setActiveStep(activeStep + 1);
                  setItem('preAssessmentChecksCompleted', 'true');
                } else if (
                  extensionsToBeBlock.every(
                    (ext: BackListExtensionFoundType) => ext.isRemoved === true,
                  )
                ) {
                  clearInterval(intervalId);
                  setTimeout(() => {
                    setActiveStep(activeStep + 1);
                  }, 4000);
                }
              }
            },
          );
        } else {
          console.error('Chrome runtime is not available');
        }
      } catch (err) {
        console.log(err);
      }
    };

    const intervalId = setInterval(fetchExtensionsList, 2000);

    return () => clearInterval(intervalId);
  };

  useEffect(() => {
    fetchExtensionsInfo();
  }, []);

  return (
    <Box sx={getListOfExtensionsCard}>
      {blackListExtensionFound.length > 0 ? (
        <Box>
          <Box sx={listWrapper}>
            <Typography variant='h3' fontWeight='300' sx={{ color: colors.darkLiverGray }}>
              {extensionsText.disableExtensionText}
            </Typography>
            <Box sx={{ height: '100%' }}>
              <Box sx={extensionListWrapperStyles}>
                {blackListExtensionFound.map(
                  (extension: BackListExtensionFoundType, index: number) => (
                    <Box
                      key={index}
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                      }}
                    >
                      <Typography variant='body1' sx={{ color: colors.darkLiverGray }}>
                        {extension?.name}
                      </Typography>
                      {extension.isRemoved ? (
                        <Lottie
                          animationData={require('../animations/loadingCheck.json')}
                          loop={true}
                          style={{ height: '30px', width: '30px' }}
                        />
                      ) : (
                        <Lottie
                          animationData={require('../animations/dotsLoader.json')}
                          loop={true}
                          style={{ height: '30px', width: '30px' }}
                        />
                      )}
                    </Box>
                  ),
                )}
              </Box>
            </Box>
          </Box>
        </Box>
      ) : (
        <Box sx={pleaseTurnOnCamSx}>
          <Box sx={iconAndTextSx}>
            <Box>
              <img
                src={noExtensionsToBlock}
                alt='zecode'
                style={{ height: '60px', width: '60px', strokeWidth: 1 }}
              />
            </Box>
            <Typography variant='h3' sx={{ color: colors.darkLiverGray }}>
              {' '}
              {extensionsText.noExtensionsDetected}{' '}
            </Typography>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export const PrimaryCameraCheck = ({
  activeStep,
  setActiveStep,
}: {
  activeStep: number;
  setActiveStep: (value: number) => void;
}) => {
  const [isCameraEnabled, setIsCameraEnabled] = useState<string | null>(null);
  const [isFaceDetected, setIsFaceDetected] = useState<boolean>(false);
  const [isSingleFace, setIsSingleFace] = useState<boolean>(false);
  const [faceDetectTimer, setFaceDetectTimer] = useState<NodeJS.Timeout | null>(null);
  const webcamRef = useRef<Webcam>(null);
  const [isModelLoaded, setIsModelLoaded] = useState<boolean>(false);

  const videoConstraints = {
    facingMode: 'user',
  };

  const loadModels = async () => {
    try {
      await Promise.all([
        faceapi.nets.tinyFaceDetector.loadFromUri(modelUrl),
        faceapi.nets.faceLandmark68TinyNet.loadFromUri(modelUrl),
        faceapi.nets.faceExpressionNet.loadFromUri(modelUrl),
      ]);
      setIsModelLoaded(true);
    } catch (error) {
      console.error('Error loading models:', error);
    }
  };

  const checkFaceVisibility = async () => {
    if (!isModelLoaded || !webcamRef.current) return;
    const video = webcamRef.current.video;
    if (video) {
      const detections = await faceapi.detectAllFaces(video, new faceapi.TinyFaceDetectorOptions());
      if (detections.length === 1) {
        setIsFaceDetected(true);
        setIsSingleFace(true);
      } else if (detections.length > 1) {
        setIsFaceDetected(true);
        setIsSingleFace(false);
      } else {
        setIsFaceDetected(false);
        setIsSingleFace(false);
      }
    } else {
      console.error('No video available for face detection.');
    }
  };

  const checkCamera = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true });
      setIsCameraEnabled(errorStepText.enabledCamera);
      stream.getTracks().forEach((track) => track.stop());
    } catch (error) {
      setIsCameraEnabled(errorStepText.disabledCamera);
    }
  };

  useEffect(() => {
    loadModels();
    checkCamera();
  }, []);

  useEffect(() => {
    if (isCameraEnabled && isModelLoaded && activeStep === 0) {
      const intervalId = setInterval(checkFaceVisibility, 1000);
      return () => clearInterval(intervalId);
    }
  }, [isCameraEnabled, isModelLoaded, activeStep]);

  useEffect(() => {
    if (isFaceDetected && isSingleFace) {
      if (!faceDetectTimer) {
        const timer = setTimeout(() => {
          setActiveStep(activeStep + 1);
        }, 4000);
        setFaceDetectTimer(timer);
      }
    } else {
      if (faceDetectTimer) {
        clearTimeout(faceDetectTimer);
        setFaceDetectTimer(null);
      }
    }
  }, [isFaceDetected, isSingleFace]);

  return (
    <>
      {isCameraEnabled === errorStepText.enabledCamera ? (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '15px',
            width: '100%',
            alignItems: 'center',
          }}
        >
          <Webcam
            audio={false}
            style={webCamStyles}
            ref={webcamRef}
            screenshotFormat='image/jpeg'
            videoConstraints={videoConstraints}
          />
          {!isFaceDetected && (
            <Typography variant='h4' sx={{ color: colors.red }}>
              {commonStrings.assessment.preAssessmentChecks.noFace}
            </Typography>
          )}
          {isFaceDetected && !isSingleFace && (
            <Typography variant='h3' sx={{ color: colors.red }}>
              {commonStrings.assessment.preAssessmentChecks.multipleFaces}
            </Typography>
          )}
        </Box>
      ) : (
        isCameraEnabled === errorStepText.disabledCamera && (
          <Box sx={pleaseTurnOnCamSx}>
            <Box sx={iconAndTextSx}>
              <Box>
                <img src={disabledCameraIcon} alt='icon' />
              </Box>
              <Typography variant='h3' sx={errorTextSx(false)}>
                {errorStepText.primaryCamera}
              </Typography>
            </Box>
          </Box>
        )
      )}
    </>
  );
};



export const InstallExtension = ({
  activeStep,
  setActiveStep,
  setBlackListExtensionFound,
  isExtensionInstalled,
}: {
  activeStep: number;
  setActiveStep: (value: number) => void;
  setBlackListExtensionFound: (value: BackListExtensionFoundType[]) => void;
  isExtensionInstalled: boolean;
}) => {
  const installCustomExtension = () => {
    const installExtension = () => {
      try {
        if (window.chrome && window.chrome.runtime) {
          window.chrome.runtime.sendMessage(
            extensionId,
            { type: 'getExtensions' }, // Message payload
            (response) => {
              if (chrome.runtime.lastError) {
                console.error('Error:', chrome.runtime.lastError.message);
              } else {
                const extensionsFound = response?.filter((extension: any) =>
                  extensionsToBeBlacklisted.includes(extension.id),
                );
                setBlackListExtensionFound(
                  extensionsFound
                    .map((extension: any) => {
                      if (extension.enabled === true) {
                        return { id: extension.id, name: extension.name, isRemoved: false };
                      }
                    })
                    .filter((obj: any) => obj !== undefined),
                );
                clearInterval(intervalId);
                setTimeout(() => {
                  setActiveStep(activeStep + 1);
                }, 4000);
              }
            },
          );
        } else {
          console.error('Chrome runtime is not available');
        }
      } catch (err) {
        console.log(err);
      }
    };
    const intervalId = setInterval(installExtension, 2000);
    return () => clearInterval(intervalId);
  };

  const installExtensionHandler = () => {
    window.open(extensionUrl, '_blank');
  };

  useEffect(() => {
    installCustomExtension();
  }, []);

  return (
    <>
      <Box sx={pleaseTurnOnCamSx}>
        <Box sx={iconAndTextSx}>
          <Box>
            <img src={groupIcon} alt='icon' />
          </Box>
          {!isExtensionInstalled ? (
            <Typography variant='h3' sx={{ color: colors.darkLiverGray }}>
              <Typography variant='h3'>{extensionsText.installZecodeIntegrityGuard}</Typography>
              <Typography variant='h3' sx={{ textAlign: 'center' }}>
                <Box component={'span'} sx={clickHereTextStyles} onClick={installExtensionHandler}>
                  {'click Here'}
                </Box>
              </Typography>
            </Typography>
          ) : (
            <Typography variant='h3' sx={{ color: colors.darkLiverGray }}>
              {extensionsText.extensionInstalled}
            </Typography>
          )}
        </Box>
      </Box>
    </>
  );
};

export const pleaseTurnOnCamSx: SxProps = {
  height: '100%',
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  background: colors.ghostWhite,
  borderRadius: '5px',
  padding: 2,
};

export const iconAndTextSx: SxProps = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '20px',
};

const listWrapper: SxProps = {
  display: 'flex',
  flexDirection: 'column',
  gap: '18px',
};

const getListOfExtensionsCard: SxProps = {
  height: '100%',
  width: '100%',
  background: colors.ghostWhite,
  borderRadius: '5px',
  padding: '24px',
};

const webCamStyles: React.CSSProperties = {
  width: '100%',
  height: 'auto',
  aspectRatio: '16/8',
  objectFit: 'cover',
  border: 'none',
  overflow: 'hidden',
  borderRadius: '5px',
};

export const errorTextSx: (value: boolean) => SxProps = (value) => ({
  color: value ? colors.darkLiverGray : colors.red,
});

const clickHereTextStyles = {
  color: colors.zecodePurple,
  cursor: 'pointer',
  textDecoration: 'underline',
};

const extensionListWrapperStyles = {
  maxHeight: '200px',
  overflow: 'auto',
  height: '100%',
  '::-webkit-scrollbar': {
    width: '0px',
  },
};
